import styled from "styled-components"
import {Title} from "../styles/text";
import {color_accent, color_primary, product_item_radius} from "../styles/globalstyle"
import Fade from '@material-ui/core/Fade';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import ControlPointOutlinedIcon from '@material-ui/icons/ControlPointOutlined';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import FireplaceIcon from '@material-ui/icons/Fireplace';
import { canAddProductToBasket } from "../services/store"

const MenuStyle = styled.div`
	margin: 0px 0px 50px 0px;
	width: 100%;
`

const ProductsList = styled.div` // list of items
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(320px, 400px));
	gap: 16px;
`

const ProductContainer = styled.div`
	height: 140px;
`

const ProductItem = styled(Title)`
	display: grid;
	grid-template-columns: ${({image}) => (image ? `minmax(180px, 260px) 140px` : `minmax(320px, 400px) 0px` ) };
	height: 140px;
	border-radius:${product_item_radius};
	box-shadow: ${({highlight}) => (highlight ? `0px 0px 0px 0px ${color_accent}` : `0px 0px 0px 0px gray` ) };
	border-style: ${({highlight}) => (highlight ? `solid` : `none` ) };
	border-color: ${({highlight}) => (highlight ? `${color_accent}` : `none` ) };
	border-width: 1px;
	transition: .3s;
	&:hover {
		cursor: pointer;
		box-shadow: 0px 0px 6px 0px ${({highlight}) => (highlight ? `${color_accent}` : `gray` ) };
	}
	background-color:white;
`

const ProductText = styled.div`
	padding: 10px;
	justify-content: space-between;
  bottom: 0;
  max-height: 92px;
  overflow: hidden;
  -webkit-mask-image: linear-gradient(180deg, #000 95px, transparent);
`
export const ProductPrice = styled.h4`
	font-size: 18px;
	color: ${color_accent};
	text-align: left;
	margin-left: 10px;
`

export const ProductOriginalPrice = styled(ProductPrice)`
	font-size: 16px;
	opacity: 0.5;
`

const ProductTitleContainer = styled.div`

`

const ProductDescriptionContainer = styled.div`

`

export const ProductName = styled.h3`
	text-align: left;
	padding:0;
	margin:0;
	margin-top: -4px;
`

const ProductDescription = styled.div`
	text-align: left;
	font-size: 14px;
	font-weight: 400;
	color: gray;
	margin-top: 2px;
`

const ProductImage = styled.div`
	height: 140px;
	
	border-radius:${product_item_radius};
	background-image: ${({image}) => `url(${image});` }
	opacity: ${({image_opacity}) => image_opacity };
	background-position: center;
	background-size: cover;
`

const BottomItemsContainer = styled.div`
	position: relative;
  bottom: 32px;
  display: grid;
  width: minmax(320px, 400px);
  height: 36px;
	grid-template-columns: auto 33px;
`

const BottomPlusContainer = styled.div`
	&:hover {
		cursor: pointer;
		box-shadow: 0px 0px 6px 0px gray;
	}
`

const iconStyleGreen = {
  fontSize: 18,
  marginLeft: 8,
  color: "green"
};

export function Menu({data, setOpenProduct, addProduct, notLoggedInCallback})
{
	return <MenuStyle>		
		{data.map(category => (
			<div key={category.id}>
				<h2>
					{category.name}
				</h2>
				<Fade in={true} timeout={1500}>
					<ProductsList>
					{category.products.map((product) => (
						<ProductContainer key={product.id}>
              <ProductItem image={product.small_image_url} highlight={product.highlight}  onClick = {() => { setOpenProduct(product) } } >
                <ProductText>
                	<ProductTitleContainer>
                		<ProductName>{product.name}</ProductName>
                	</ProductTitleContainer>
                	<ProductDescriptionContainer>
                		<ProductDescription>{product.description}</ProductDescription>
                	</ProductDescriptionContainer>
                </ProductText>
            		<ProductImage image={product.small_image_url} image_opacity={product.image_opacity} />
              </ProductItem>
              	<BottomItemsContainer>
              		<div>
	              		{product.price>0?<div style={{float:"left"}}>
	              			{product.original_price && product.original_price !== 0?<ProductOriginalPrice style={{display:"inline-block", marginTop: "4px",}} ><s>{product.original_price} zł</s></ProductOriginalPrice>:null}
	                  	{" "}
	                  	<ProductPrice style={{display:"inline-block", marginTop: "4px",}} >{product.price} zł</ProductPrice>
	                  </div>:null}
	                  <div style={{float:"left"}}>
	                  
	                  	{product.markings&&product.markings.cutlery_required?<RestaurantIcon style={{color: "#CDCDCD", fontSize: "18px", marginTop:"4px", marginLeft: "8px"}} />:null}
	                  	{product.markings&&product.markings.heating_required?<FireplaceIcon style={{color: "#CDCDCD", fontSize: "18px", marginTop:"4px", marginLeft: "8px"}} />:null}

	                  	{product.diets&&product.diets.glutenfree?<img src="../images/diet_glutenfree_icon.png" alt="glutenfree icon" width="18" height="18" categoriesData style={{marginTop:"6px", marginLeft: "8px"}} />:null}
	                  	{product.diets&&product.diets.vegetarian?<img src="../images/diet_vegetarian_icon.png" alt="vegetarian icon" width="18" height="18" style={{marginTop:"6px", marginLeft: "8px"}} />:null}
	                  	{product.diets&&product.diets.vegan?<img src="../images/diet_vegan_icon.png" alt="vegan icon" width="18" height="18" style={{marginTop:"6px", marginLeft: "8px"}} />:null}
	                  	{product.spice_level&&product.spice_level>0?[...Array(product.spice_level)].map((x, i) =>
												<img src="../images/spice_level_icon.png" alt="spice icon" width="14" height="18" style={{marginTop:"6px", marginLeft: i===0?"8px":"0px"}} />
											):null}
											{product.promotion_tag==="zero_waste"?<AutorenewIcon style={iconStyleGreen} />:null}
	              		</div>
              		</div>
              	{product.price>0?<div>
              			{product.stock===-1?<BottomPlusContainer style={{margin:"8px",borderRadius: "16px", width:"32px", height:"32px", backgroundColor:"white"}} disabled  onClick = {
	              			() => {
	              					if (canAddProductToBasket())
	              					{
		              					if ((product.options && product.options.length>0) || (product.modifiers && product.modifiers.length>0))
														{ 
															setOpenProduct(product) 
														} 
														else 
														{ 
															addProduct(product) 
														} 
													} 
													else
													{
														notLoggedInCallback()
													}
	              				
	              				}
              			}>
	              			<div style={{padding:"4px"}}> 
												{product.quantity>0?<div style={{color:color_primary, fontSize:"18px", fontWeight:"800", textAlign:"center"}}>{product.quantity}
												</div>:<ControlPointOutlinedIcon color="primary" />}
	              			</div>
              			</BottomPlusContainer>:<BottomPlusContainer style={{margin:"8px",borderRadius: "16px", width:"32px", height:"44px", backgroundColor:"white"}} disabled  onClick = {
	              			() => {
	              					if (canAddProductToBasket())
	              					{
		              					if ((product.options && product.options.length>0) || (product.modifiers && product.modifiers.length>0))
														{ 
															setOpenProduct(product) 
														} 
														else 
														{ 
															addProduct(product) 
														} 
													} 
													else
													{
														notLoggedInCallback()
													}
	              				
	              				}

	              			}>
	              			<div style={{padding:"4px"}}> 
												{product.quantity>0?<div style={{color:color_primary, fontSize:"18px", fontWeight:"800", textAlign:"center"}}>{product.quantity}

												</div>:<ControlPointOutlinedIcon color="primary" />}
												<div style={{color:color_primary, fontSize:"9px", fontWeight:"800", textAlign:"center", width: "32px", marginLeft:"-3px", marginTop: "-4px"}}>{product.stock-product.quantity} szt.
													
												</div>
	              			</div>
              			</BottomPlusContainer>}
              			
              			</div>:null}
              	</BottomItemsContainer>
              </ProductContainer>
            ))}
					</ProductsList>
        </Fade>
			</div>
		))}
	</MenuStyle>
}