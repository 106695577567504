import { BrowserRouter as Router, Switch, Route, useLocation } from "react-router-dom";
import Home from './pages/Home';
import Store from './pages/Store';
import Start from './pages/Start';
import Checkout from './pages/Checkout';
import Orders from './pages/Orders';
import ThankYou from './pages/ThankYou';
import MyAccount from './pages/MyAccount'
import Locations from './pages/Locations'
import PaymentError from './pages/PaymentError'
import StoreRegulation from './pages/StoreRegulation'
import Mobile from './pages/Mobile'
import CookieConsent from "react-cookie-consent";
import Health from './pages/Health'
import LoyaltyZio from './pages/LoyaltyZio'
import { refreshUserData } from "./services/user"
import { initBasket } from "./services/basket"


import { ThemeProvider, createMuiTheme, makeStyles } from '@material-ui/core/styles';
import { createGlobalStyle } from "styled-components"

import Parse from 'parse';

import TagManager from 'react-gtm-module'

import {color_primary, color_accent, main_font, deployment_type} from "./styles/globalstyle";

const tagManagerArgs = {
  gtmId: 'GTM-KMBQ23Z'
}
TagManager.initialize(tagManagerArgs)

// // PROD  
Parse.initialize("APzdQAwxfu3wawCWWGedQ6ne2PlnDXeMJFY31cXS", "LSxq6Si6G6eWMn7sIGZyrVwtAd2pmquCaHOkUYWe");
Parse.serverURL = "https://zio.b4a.io/";

// DEV
// Parse.initialize("PAHRoleN7bsZBErF69sRYLwHTLFrQpxcRN5ZyRbB", "94DUOe5j8ZM2k8uisA8TnLboesbhaatcJB2tGOoe");
// Parse.serverURL = "https://ziodev.b4a.io/";

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    font-family: ${main_font};
    background-color: rgba(250, 250, 250, 255);
  }

  h1, h2 {
    font-family: ${main_font};
    font-size: 28px;
    font-weight: 600;
    margin-top: 24px;
    margin-bottom: 16px;
  }

  h3 {
    font-family: ${main_font};
    font-size: 20px;
    font-weight: 600;
    margin-top: 24px;
    margin-bottom: 16px;
  }

  h4 {
    font-family: ${main_font};
    color: gray;
    font-size: 20px;
    font-weight: 600;
    margin-top: 24px;
    margin-bottom: 16px;
  }
`

const theme = createMuiTheme({
  palette: {
    primary: {
      main: color_primary
    },
    secondary: {
      main: color_accent
    }
  }
});


const checkStatus = (status) => {
  if(status) {
    // To set a cookies
  }
};


export default function App() {
  initBasket()
  refreshUserData()
  return (
    <>
      {deployment_type=="zio"?<ThemeProvider theme={theme}>
      <Router>
        <GlobalStyle/>
          <div className="App">
            <Switch>
              <Route path="/" exact component={Home}></Route>
              <Route path="/store/" exact component={Home}></Route>
              <Route path="/store/:store_id" exact component={Store}></Route>
              <Route path="/start" exact component={Start}></Route>
              <Route path="/checkout" exact component={Checkout}></Route>
              <Route path="/orders" exact component={Orders}></Route>
              <Route path="/thankyou" exact component={ThankYou}></Route>
              <Route path="/myaccount" exact component={MyAccount}></Route>
              <Route path="/paymenterror" exact component={PaymentError}></Route>
              <Route path="/mobile" exact component={Mobile}></Route>
              <Route path="/health" exact component={Health}></Route>
              <Route path="/loyalty-zio" exact component={LoyaltyZio}></Route>
            </Switch>
          </div>
        </Router>
        <CookieConsent 
          background={'#ffffff'}
          bottomPosition={true}
          buttonText={'OK'}
          buttonBackground={'#8363B7'}
          buttonColor={'#ffffff'}
          buttonFontSize={16}
          color={'#000000'}
          consentFunction={checkStatus}
          padding={20}
        >
          Ta strona wykorzystuje pliki cookie, aby świadczyć usługi na najwyższym poziomie. Dalsze korzystanie ze strony oznacza, że zgadzasz się na ich użycie. Zobacz: <a href={'https://www.zioapp.pl/pl/regulaminy/polityka-prywatnosci-i-cookies/'} style={{ color: '#000000' }}>Polityka prywatności</a>
        </CookieConsent>
      </ThemeProvider>:<ThemeProvider theme={theme}>
      <Router>
        <GlobalStyle/>
        <div className="App">
          <Switch>
            <Route path="/" exact component={Store}></Route>
            <Route path="/store/:custom_store_id" exact component={Store}></Route>
            <Route path="/start" exact component={Store}></Route>
            <Route path="/checkout" exact component={Checkout}></Route>
            <Route path="/orders" exact component={Orders}></Route>
            <Route path="/thankyou" exact component={ThankYou}></Route>
            <Route path="/myaccount" exact component={MyAccount}></Route>
            <Route path="/paymenterror" exact component={PaymentError}></Route>
            <Route path="/mobile" exact component={Mobile}></Route>
            <Route path="/health" exact component={Health}></Route>
            <Route path="/loyalty-zio" exact component={LoyaltyZio}></Route>
            <Route path="/regulation" exact component={StoreRegulation}></Route>
            <Route path="/locations" exact component={Locations}></Route>
          </Switch>
        </div>
      </Router>
      <CookieConsent 
        background={'#ffffff'}
        bottomPosition={true}
        buttonText={'OK'}
        buttonBackground={'#8363B7'}
        buttonColor={'#ffffff'}
        buttonFontSize={16}
        color={'#000000'}
        consentFunction={checkStatus}
        padding={20}
      >
        Ta strona wykorzystuje pliki cookie, aby świadczyć usługi na najwyższym poziomie. Dalsze korzystanie ze strony oznacza, że zgadzasz się na ich użycie. Zobacz: <a href={'https://www.zioapp.pl/pl/regulaminy/polityka-prywatnosci-i-cookies/'} style={{ color: '#000000' }}>Polityka prywatności</a>
      </CookieConsent>
      </ThemeProvider>}
    </>
  );
}
