import styled from "styled-components"
// import {Product, ProductGrid, ProductImage, ProductLabel, ProductPrice, ProductName} from "../components/product_grid"
import {Title} from "../styles/text";
import {color_accent} from "../styles/globalstyle"
import Fade from '@material-ui/core/Fade';

const MenuStyle = styled.div`
	margin: 0px 0px 50px 0px;
	width: 100%;
`

const ProductsList = styled.div` // list of items
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(192px, 192px));
	gap: 16px;
`

const ProductItem = styled(Title)`
	width: 192px;
	border-radius:8px;
	box-shadow: 0px 0px 0px 0px grey;
	transition: .3s;
	&:hover {
		cursor: pointer;
		box-shadow: 0px 0px 6px 0px grey;
	}
	background-color: white;
`

const ProductText = styled.div`
	font-size: 18px;
	padding: 10px;
	justify-content: space-between;
  	vertical-align: text-top;
`
export const ProductPrice = styled.div`
	font-size: 18px;
	color: ${color_accent};
	text-align: left;
`

export const ProductOriginalPrice = styled(ProductPrice)`
	font-size: 16px;
	opacity: 0.5;
`

const ProductTitleContainer = styled.div`
	max-height: 44px;
  	overflow: hidden;
`

export const ProductName = styled.div`
	text-align: left;
`

const ProductImage = styled.div`
	height: 192px;
	width: 192px;

	border-radius:8px;
	background-image: ${({image}) => `url(${image});` }
	background-position: center;
	background-size: cover;
`

const BottomItemsContainer = styled.div`

`

export function HomeProducts({data, goToStore})
{
	return <MenuStyle>		
		{data.map(list => (
			list.items.length>0?<div key={list.tag}>
				<h2>
					{list.name}
				</h2>
				<Fade in={true} timeout={1500}>
					<ProductsList>
					{list.items.map((product) => (
	                    <ProductItem key={product.id} onClick = {() => { goToStore(product.store_object_id) }  } >
	                    	<ProductImage image={product.image_url} />
	                        <ProductText>
	                        	<ProductTitleContainer>
	                        		<ProductName>{product.name}</ProductName>
	                        	</ProductTitleContainer>
	                        	<BottomItemsContainer>
	                            	{product.original_price && product.original_price !== 0?<ProductOriginalPrice style={{display:"inline-block", marginTop: "4px",}} ><s>{product.original_price} zł</s></ProductOriginalPrice>:null}
	                            	{" "}
	                            	{product.price>0?<ProductPrice style={{display:"inline-block", marginTop: "4px",}} >{product.price} zł</ProductPrice>:null}
	                            	{product.diets&&product.diets.glutenfree?<img src="../images/diet_glutenfree_icon.png" alt="glutenfree icon" width="18" height="18" style={{marginBottom: "-2px", marginLeft: "8px"}} />:null}
	                            	{product.diets&&product.diets.vegetarian?<img src="../images/diet_vegetarian_icon.png" alt="vegetarian icon" width="18" height="18" style={{marginBottom: "-2px",marginLeft: "8px"}} />:null}
	                            	{product.diets&&product.diets.vegan?<img src="../images/diet_vegan_icon.png" alt="vegan icon" width="18" height="18" style={{marginBottom: "-2px",marginLeft: "8px"}} />:null}
	                        	</BottomItemsContainer>
	                        </ProductText>
	                    </ProductItem>
	                  ))}
					</ProductsList>
                </Fade>
			</div>:null
		))}
	</MenuStyle>
}