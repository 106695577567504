import {useState} from 'react';
import styled from "styled-components"
import DirectionsBikeIcon from '@material-ui/icons/DirectionsBike';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import PhoneAndroidOutlinedIcon from '@material-ui/icons/PhoneAndroidOutlined';
import PaymentOutlinedIcon from '@material-ui/icons/PaymentOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';
import PersonIcon from '@material-ui/icons/Person';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import AddIcon from '@material-ui/icons/Add';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import EventIcon from '@material-ui/icons/Event';
import BusinessIcon from '@material-ui/icons/Business';

import Button from '@material-ui/core/Button';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import {AddressInput, SingleInput} from "../components/dialogs"


const SettingsContainer = styled.div`
    padding-top:8px;
    padding-bottom:8px;

`

const SettingsRow = styled.div`
	display: grid;
  	grid-template-columns: 50px auto 30px;
  	height: 40px;
`

const SelectRow = styled.div`
	display: grid;
  	grid-template-columns: 50px auto;
  	height: 40px;
`

const IconWrapper = styled.div`
	margin-top: -5px;
`

const SettingsLabel = styled.div`
	font-family: 'Quicksand', sans-serif;
	font-weight: 400;
    font-size: 18px;
	${({highlight}) => (highlight ? `color: red` : `color: black` )};
`

const WarningLabel = styled.div`
	font-size: 14px;
	color: red;
	margin-top: -12px;
`

const SuccessLabel = styled.div`
	font-size: 14px;
	color: green;
	margin-top: -12px;
`

const SettingsButton = styled.div`
	color: gray;
`

const HoverContainer = styled.div`
	&:hover {
		cursor: pointer;
	}
`

const OrderNote = styled.div`
    display: grid;
    grid-template-columns: auto 160px;
    font-family: 'Quicksand', sans-serif;
    font-weight: 400;
    font-size: 18px;
    padding-top: 8px;
`

const MaterialIcon = ({ icon }) => {
  
	if (icon === "bike")
	{
	  return <DirectionsBikeIcon fontSize="large"/>
	}
	else if (icon === "collect")
	{
	  return <DirectionsWalkIcon fontSize="large"/>
	}
	else if (icon === "clock")
	{
	  return <AccessTimeIcon fontSize="large"/>
	}
	else if (icon === "bell")
	{
	  return <NotificationsNoneIcon fontSize="large"/>
	}
	else if (icon === "location")
	{
	  return <LocationOnOutlinedIcon fontSize="large"/>
	}
	else if (icon === "phone")
	{
	  return <PhoneAndroidOutlinedIcon fontSize="large"/>
	}
	else if (icon === "payment")
	{
	  return <PaymentOutlinedIcon fontSize="large"/>
	}
	else if (icon === "user")
	{
	  return <PersonIcon fontSize="large"/>
	}
	else if (icon === "promocode")
	{
		return <LocalOfferIcon fontSize="large"/>
	}
	else if (icon === "edit")
	{
		return <EditOutlinedIcon/>
	}
	else if (icon === "plus")
	{
		return <AddIcon/>
	}
	else if (icon === "trophy")
	{
		return <EmojiEventsIcon fontSize="large"/>
	}
	else if (icon === "arrow-right")
	{
		return <ArrowForwardIcon/>
	}
	else if (icon === "legal")
	{
		return <AccountBalanceIcon fontSize="large"/>
	}
	else if (icon === "calendar")
	{
		return <EventIcon fontSize="large"/>
	}
	else if (icon === "business")
	{
		return <BusinessIcon fontSize="large"/>
	}
	

	return <div></div>
}

export function CheckoutAddressRow({title, highlight, popupTitle, icon, onChange, onClose, openDialog, setOpenDialog, onOpen})
{
	const handleClickOpen = () => {
		setOpenDialog(true);
		if (onOpen) 
		{
			onOpen();
		}
	};

	const handleClose = () => {
		setOpenDialog(false);
	};
	
	const handleSave = () => {
		setOpenDialog(false);
		onClose()
	};
	
	return <HoverContainer>
		<AddressInput open={openDialog} handleClose={handleClose} handleSave={handleSave} onChange={onChange} title={popupTitle} />
		<CheckoutInfoRow onClick={ handleClickOpen } title={title} icon={icon} highlight={highlight?true:false} buttonIcon={"edit"} />
	</HoverContainer>
}

export function CheckoutLegalInformationRow({title, icon})
{
	return <CheckoutInfoRow title={title} icon={icon} />
}

export function CheckoutDeliveryNotesRow({title, deliveryNote, popupTitle, paceholder, icon, onChange})
{
	const [open, setOpen] = useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};
	
	return <HoverContainer>
		<SingleInput open={open} handleClose={handleClose} value={deliveryNote} onChange={onChange} handleSave={handleClose} title={popupTitle} paceholder={paceholder}/>
		<CheckoutInfoRow onClick={ handleClickOpen } title={title} icon={icon} highlight={deliveryNote===""?true:false} buttonIcon={"edit"} />
	</HoverContainer>
}

export function CheckoutCustomerName({title, customeName, popupTitle, icon, onChange, onClose})
{
	const [open, setOpen] = useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};
	
	const handleSave = () => {
		setOpen(false);
		if (onClose) 
		{
			onClose()
		}
	};

	return <HoverContainer>
		<SingleInput open={open} handleClose={handleClose} value={customeName} onChange={onChange} handleSave={handleSave} title={popupTitle} paceholder={"imię i nazwisko"}/>
		<CheckoutInfoRow onClick={ handleClickOpen } title={title} icon={icon} highlight={customeName===""?true:false} buttonIcon={"edit"} />
	</HoverContainer>
}

export function CheckoutPhoneNumberRow({title, phoneNumber, popupTitle, icon, onChange, onClose})
{
	const [open, setOpen] = useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleSave = () => {
		setOpen(false);
		onClose()
	};
	
	return <HoverContainer>
		<SingleInput open={open} handleClose={handleClose} value={phoneNumber} onChange={onChange} handleSave={handleSave} title={popupTitle} paceholder={"xxx xxx xxx"} inputType="number" />
		<CheckoutInfoRow onClick={ handleClickOpen } title={title} icon={icon} highlight={phoneNumber===""?true:false} buttonIcon={"edit"} />
	</HoverContainer>
}

export function CheckoutNoteRow({rowLabel, buttonLabel, popupTitle, onChange, onClose})
{
	const [open, setOpen] = useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleSave = () => {
		setOpen(false);
		// onClose()
	};
	
	return <HoverContainer>
		<OrderNote>
            <div>
                {rowLabel}
            </div>
             <Button color="primary" onClick={ handleClickOpen } >{buttonLabel}</Button>
        </OrderNote>
		<SingleInput open={open} handleClose={handleClose} value={rowLabel} onChange={onChange} handleSave={handleSave} title={popupTitle} paceholder={"notatka"} />
	</HoverContainer>
}	

export function CheckoutInfoRow({title, icon, onClick, highlight, buttonIcon, warrningText, successText})
{
	return <SettingsContainer>
		<SettingsRow onClick={onClick} >
			<IconWrapper>
				<MaterialIcon icon={icon} />
			</IconWrapper>
			<SettingsLabel highlight={highlight} >
				{title}
			</SettingsLabel>
			<SettingsButton>
				{buttonIcon?<MaterialIcon icon={buttonIcon}/>:null}
			</SettingsButton>
			{/*{showEditIcon?<SettingsButton/>:null}*/}
		</SettingsRow>
		{warrningText&&warrningText!==""?<SettingsRow>
			<div></div>
			<WarningLabel>
					{warrningText}
			</WarningLabel>
		</SettingsRow>:null}
		{successText&&successText!==""?<SettingsRow>
			<div></div>
			<SuccessLabel>
					{successText}
			</SuccessLabel>
		</SettingsRow>:null}
	</SettingsContainer>
}

export function CheckoutButtonRow({title, value, popupTitle, icon, buttonIcon, onChange, onClose, warrningText, successText})
{
	const [open, setOpen] = useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleSave = () => {
		setOpen(false);
		onClose()
	};
	return <HoverContainer>
		<SingleInput open={open} value={value} handleClose={handleClose} onChange={onChange} handleSave={handleSave} title={popupTitle} paceholder={"wpisz"} />
		<CheckoutInfoRow onClick={ handleClickOpen } title={title} icon={icon} buttonIcon={buttonIcon} warrningText={warrningText} successText={successText} />
	</HoverContainer>
}	

export function CheckoutSelectRow({title, icon, items, setValue})
{
	return <SettingsContainer>
		<SelectRow>
			<MaterialIcon icon={icon} />
			{items!==null&&items.length>0?<Select
	          labelId="demo-controlled-open-select-label"
	          id="demo-controlled-open-select"
	          // open={open}
	          // onClose={handleClose}
	          // onOpen={handleOpen}
	          value={title}
	          onChange={(event) => { console.log(event.target); setValue(event.target.value); }}
	        >
	        	<MenuItem value={title}>
	            	<em style={{fontFamily: 'Quicksand', fontWeight: 400, fontSize: "18px"}}>{title}</em>
	          	</MenuItem>
	        	{items.map(item => (item.name!==title?<MenuItem value={item.tag}>{item.name}</MenuItem>:null ))}
	        </Select>:null}
		</SelectRow>
	</SettingsContainer>
}
