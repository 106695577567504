import styled from "styled-components"
import CircularProgress from '@material-ui/core/CircularProgress';

const Background = styled.div`
	position: absolute;
	background-color: rgba(255, 255, 255, 0.6);
	width: 100%;
	height: 100%;
	z-index: 1000;
`

const Item = styled.div`
	position: absolute;
	
	margin-top: 320px;
	margin-left: 50%;
`


export function Loader({show}){

	return show?<Background>
		<Item>
			<CircularProgress color="secondary"/>
		</Item>
	</Background>:null
}
