const list = {
	eggs: "jajka",
	fish: "ryby",
	diary: "mleko i przetwory mleczne",
	nuts: "orzechy",
	soya: "soja",
	peanuts: "orzeszki ziemne",
	muscles: "mięczaki",
	shellfish: "skorupiaki",
	lupin: "łubin",
	mustard: "gorczyca",
	celery: "seler",
	gluten: "gluten i zboża zawierające gluten",
	sesame: "sezam"
}

export function getAllergens(keys)
{
  	let _str = ""
	if (keys && keys.length > 0)
	{
		_str = "*Alergeny: "
		for (let i = 0; i < keys.length; i++)
		{
			if (list[keys[i]])
			{
				_str = _str + list[keys[i]]

				if (i < keys.length-1)
				{
					_str = _str + ", "
				} 
			} 
		}
	} 
	

	if (_str === "")
	{
		_str = "*Jeśli masz alergie i nie jesteś pewien tego produktu, skontaktuj się z sprzedawcą w celu uzyskania pełnych informacji."
	} 
	return _str
};