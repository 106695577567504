import styled from "styled-components"
import Fade from '@material-ui/core/Fade';

const Order = styled.div`
	height: 180px;
	border-radius:6px;
	box-shadow: ${({isActive}) => (isActive === true ? `0px 0px 6px 0px grey;` : `0px 0px 2px 0px grey;` )}
	transition-duration: .3s;
	margin-bottom: 16px;
`

const Container = styled.div`
	display: grid;
	grid-template-columns: 200px auto 200px;
`

const TextContainer = styled.div`
	padding-top: 6px;
`

const StoreName = styled.div`
	text-align: left;
	font-size: 24px;
`

const Label = styled.div`
	text-align: left;
	margin-top: 4px;
	color: gray;
	font-size: 16px;
`

const DeliveryTime = styled.div`
	text-align: center;
	margin-top: 6px;
	font-size: 32px;
	font-weight:800;
	padding-right: 6px;
`

const DeliveryTimeLabel = styled.div`
	text-align: center;
	margin-top: 40px;
	font-size: 18px;
`

const DeliveryTimeNote = styled.div`
	text-align: center;
	margin-top: 6px;
	font-size: 18px;
`

export const Logo = styled.div`
	width: 160px;
	height: 160px;
	margin-top: 10px;
	margin-left: 10px;
	border-radius:6px;
	background-image: ${({image}) => `url(${image});` }
	background-position: center;
	background-size: cover;

`

export function OrderRow(props)
{
	return <>
		{props.orders.map(order => (
			
			<Fade key={order.object_id} in={true} timeout={1500}>
				<Order isActive={props.isActive}>
					<Container>
						<Logo image={order.store_logo_url} onClick={()=>{ props.logoCallback(order.store_object_id) }}/>
						<TextContainer>
							<StoreName >
								{order.store_name}
							</StoreName>
							<Label >
								kod odbioru: <b>{order.order_id}</b>
							</Label>
							<Label >
								{order.products_quantity} pozycji: {order.order_value} zł
							</Label>
							<Label >
								{order.completion_date}
							</Label>
						</TextContainer>
						{order.delivery_type&&order.delivery_type === "delivery"?<> 
							{order.completion_time?<div>
								<DeliveryTimeLabel >
									dostawa
								</DeliveryTimeLabel>
								<DeliveryTimeNote>
									{order.completion_date}
								</DeliveryTimeNote>
								<DeliveryTime>
									{order.completion_time}
								</DeliveryTime>
								{order.schedule_delivery_note&&order.schedule_delivery_note!=null?<DeliveryTimeNote >
									{order.schedule_delivery_note}
								</DeliveryTimeNote>:null}
							</div>:null}
						</>:<> 
							{order.status < 2?<> 
								{order.completion_time?<div>
									<DeliveryTimeLabel >
										Odbiór osobisty
									</DeliveryTimeLabel>
									<DeliveryTimeNote>
										{order.completion_date}
									</DeliveryTimeNote>
									<DeliveryTime>
										{order.completion_time}
									</DeliveryTime>
									{order.schedule_collect_note&&order.schedule_collect_note!=null?<DeliveryTimeNote >
										{order.schedule_collect_note}
									</DeliveryTimeNote>:null}
								</div>:null}
							</>:<> 
								{order.completion_time?<div style={{ color:"green"}}>
									<DeliveryTimeLabel >
										Gotowe do odbioru
									</DeliveryTimeLabel>
									<DeliveryTime>
										zapraszamy
									</DeliveryTime>
								</div>:null}
							</>}
							
						</>}
					</Container>
				</Order>
			</Fade>
			))
		}
	</>
}