import stores_data from "../data/stores"
import { getStatusOnDeliveryDistance } from "../services/delivery"
import { getDistance } from "../services/helper"
import { isLoggedIn, isUserAddressAdded } from "../services/user"

const _week_days = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
]
const currentDate = new Date(); 
const current_day = currentDate.getDay();

let current_store_data = {}

export function IsStoreOpen(is_open, store_opening_hours)
{
  const _week_day = current_day
  let _is_open = false
  if (is_open === true && store_opening_hours.is_active === true )
  {
    if (store_opening_hours[ _week_days[_week_day] ]) 
    {
      if (store_opening_hours[ _week_days[_week_day] ].is_open)
      {
        const _time_hour = currentDate.getHours()
        const _time_min = currentDate.getMinutes()
        if (store_opening_hours[ _week_days[_week_day] ].open.hour <= _time_hour && store_opening_hours[ _week_days[_week_day] ].close.hour >= _time_hour)
        {
          if (store_opening_hours[ _week_days[_week_day] ].open.hour === _time_hour)
          {
            if (store_opening_hours[ _week_days[_week_day] ].open.min <= _time_min)
            {
              _is_open = true
            }
          }
          else if (store_opening_hours[ _week_days[_week_day] ].close.hour === _time_hour)
          {
            if (store_opening_hours[ _week_days[_week_day] ].close.min > _time_min)
            {
              _is_open = true
            }
          }
          else
          {
            _is_open = true
          }
        }      
      } 
    }
  } 
  return _is_open
};

function returnMinAsString(min)
{
    if (min < 10)
    {
        return "0" + min.toString()
    }
    else
    {
        return min.toString()
    }
}

function returnOpeningTimeString(data)
{
    return data.open.hour + ":" + returnMinAsString(data.open.min) + " - " + data.close.hour + ":" + returnMinAsString(data.close.min)
}

export function OpeningTimeInfo(is_open, store_opening_hours)
{
    const _week_day = current_day
    let _opening_time_text = "Teraz jesteśmy zamknięci, wróć później."
    if (is_open && store_opening_hours) // and store_opening_hours.is_active
    {
        if (store_opening_hours[ _week_days[_week_day] ])
        {
            if (store_opening_hours[ _week_days[_week_day] ].is_open)
            {
                const _time = {}
                _time.hour = currentDate.getHours()
                _time.min = currentDate.getMinutes()

                if (_time.hour >= store_opening_hours[ _week_days[_week_day] ].open.hour && _time.hour <= store_opening_hours[ _week_days[_week_day] ].close.hour)
                {
                    if (store_opening_hours.is_active)
                    {
                        if (_time.hour == store_opening_hours[ _week_days[_week_day] ].open.hour)
                        {
                            if (_time.min >= store_opening_hours[ _week_days[_week_day] ].open.min)
                            {
                                _opening_time_text = "Otwarte " + returnOpeningTimeString(store_opening_hours[ _week_days[_week_day] ])
                            }
                        }
                        else if (_time.hour == store_opening_hours[ _week_days[_week_day] ].close.hour)
                        {
                            if (_time.min < store_opening_hours[ _week_days[_week_day] ].close.min)
                            {
                                _opening_time_text = "Ostatnie zamówienia, zamykamy o " + store_opening_hours[ _week_days[_week_day] ].close.hour + ":" + returnMinAsString(store_opening_hours[ _week_days[_week_day] ].close.min)
                            } 
                        } 
                        else if (_time.hour < store_opening_hours[ _week_days[_week_day] ].open.hour)
                        {
                            _opening_time_text = "Teraz jesteśmy zamknięci, otwieramy o " + store_opening_hours[ _week_days[_week_day] ].open.hour + ":" + returnMinAsString(store_opening_hours[ _week_days[_week_day] ].open.min)
                        } 
                        else if (_time.hour == store_opening_hours[ _week_days[_week_day] ].close.hour - 1)
                        {
                            _opening_time_text = "Przyjmujemy zamówienia do " + store_opening_hours[ _week_days[_week_day] ].close.hour + ":" + returnMinAsString(store_opening_hours[ _week_days[_week_day] ].close.min)
                        } 
                        else
                        {
                            _opening_time_text = "otwarte " + returnOpeningTimeString(store_opening_hours[ _week_days[_week_day] ])
                        }
                    }
                    else
                    { 
                        if (_time.hour >= store_opening_hours[ _week_days[_week_day] ].open.hour)
                        {
                            _opening_time_text = "Zamknięte, godziny otwarcia " + returnOpeningTimeString(store_opening_hours[ _week_days[_week_day] ])
                        } 
                        else
                        {
                            _opening_time_text = "Niebawem otwieramy, wróć później."
                        }
                    }
                }
                else
                {
                    _opening_time_text = "Zamknięte, godziny otwarcia " + returnOpeningTimeString(store_opening_hours[ _week_days[_week_day] ])
                } 
            }
            else
            {
                _opening_time_text = "Zamknięte. Sprawdź dni i godziny otwarcia."
            }
        }  
    } 
    return _opening_time_text
}

export function SetCurrentStoreData(data)
{
    current_store_data.require_phone_number = true 
    current_store_data.require_user_name = true 
    current_store_data.require_address = true

    current_store_data.name = data.name
    current_store_data.object_id = data.object_id
    current_store_data.store_user_object_id = data.store_user_object_id
    current_store_data.delivery_information = data.delivery_information
    current_store_data.address_information = data.address_information
    current_store_data.delivery_price = data.delivery_price
    current_store_data.minimum_order_value = data.minimum_order_value
    current_store_data.distance = data.distance
    current_store_data.payment_method = data.payment_method
    current_store_data.store_type = data.store_type
    current_store_data.collect_waiting_time = data.collect_waiting_time
    current_store_data.delivery_waiting_time = data.delivery_waiting_time

    current_store_data.delivery_location = {}
    if (data.delivery_information.location)
    {
       current_store_data.delivery_location = data.delivery_information.location
    } 
    else
    {
      current_store_data.delivery_location = data.location
    }
    current_store_data.store_location = data.location

    current_store_data.delivery_type = data.delivery_type
    current_store_data.delivery_types_list = []
    if (data.delivery_type.delivery === true)
    {
      current_store_data.delivery_types_list.push({name:"Dostawa", tag:"delivery"})
    }
    else  
    {
        current_store_data.require_address = false
    }
    if (data.delivery_type.collect === true)
    {
      current_store_data.delivery_types_list.push({name:"Odbiór osobosty", tag:"collect"})
    }

    current_store_data.completion_type = data.completion_type
    current_store_data.completion_types_list = []
    if (data.completion_type.asap === true)
    {
      current_store_data.completion_types_list.push({name:"Jak najszybciej", tag:"asap"})
    }
    if (data.completion_type.today === true)
    {
      current_store_data.completion_types_list.push({name:"Zaplanujna dziś", tag:"today"})
    }
    if (data.completion_type.schedule === true)
    {
      current_store_data.completion_types_list.push({name:"Wybierz date", tag:"schedule"})
    }

    if (data.misc)
    {
        if (data.misc.thank_you_description)
        {
            current_store_data.thank_you_description = data.misc.thank_you_description
        }
        if (data.misc.regulation_object_id)
        {
            current_store_data.regulation_object_id = data.misc.regulation_object_id
        }
        if (data.misc.require_phone_number === false)
        {
            current_store_data.require_phone_number = data.misc.require_phone_number
        }
    }

    window.localStorage.setItem("current_store_data", JSON.stringify(current_store_data) )
}

export function getCurrentStoreData()
{
    return JSON.parse(window.localStorage.getItem("current_store_data") )//current_store_data
}

export function getCurrentStoreDataKey(key)
{
    return JSON.parse(window.localStorage.getItem("current_store_data") )[key] //current_store_data
}

export function clearCurrentStoreData()
{
    for (var k in current_store_data){
        current_store_data[k] = null
    }
    window.localStorage.removeItem("current_store_data")
}


export function isStoreWithinRange()
{
    const _store_raw = window.localStorage.getItem("current_store_data")
    const _store = JSON.parse(_store_raw)
    let _is_within_range = false
    if (_store.delivery_location !== null && _store.delivery_location.latitude !== null)
    {
        const lat = localStorage.getItem("primary_address_latitude")
        const lon = localStorage.getItem("primary_address_longitude")
        const delivery_distance = getDistance({ latitude: lat, longitude: lon },{ latitude: _store.delivery_location.latitude, longitude: _store.delivery_location.longitude  }  )
        if (getStatusOnDeliveryDistance(_store.delivery_information, {delivery: true}, delivery_distance) === "ok") 
        {
            _is_within_range = true
        }
    }
    return _is_within_range
};

export function getStoreDistance()
{
    const _store_raw = window.localStorage.getItem("current_store_data")
    const _store = JSON.parse(_store_raw)
    
    if (_store.store_location !== null && _store.store_location.latitude !== null)
    {
        const lat = localStorage.getItem("primary_address_latitude")
        const lon = localStorage.getItem("primary_address_longitude")
        return getDistance({ latitude: lat, longitude: lon },{ latitude: _store.store_location.latitude, longitude: _store.store_location.longitude  }  )
    }
    return false
}


export function setDefaultStore(history)
{
  for (let i = 0; i < stores_data.length; i++)
  {
    if (window.location.href.search(stores_data[i].domain + ".zamow.org") != -1 || window.location.href.search(stores_data[i].domain + ".pl") != -1 || window.location.href.search(stores_data[i].domain + ":3000") != -1 || window.location.href.search(stores_data[i].domain + ".ziofood.pl") != -1)
    {
        let store_object_id = ""
        if (stores_data[i].user_object_id) // if user is available then dont reset store id
        {
            store_object_id = window.localStorage.getItem("custom_store_object_id")
        }   
        else  
        {
            window.localStorage.setItem("custom_store_object_id", stores_data[i].store_object_id);
            store_object_id = stores_data[i].store_object_id
            if (window.localStorage.getItem("custom_store_user_object_id")) // if configuration has changed and there are not locations anymore, reset setting.
            {
                window.localStorage.removeItem("custom_store_user_object_id")
            }
        }
      return store_object_id
      break
    }
  }
  return null
}

export function isCustomStoreAvailable()
{
  const _store_object_id = window.localStorage.getItem("custom_store_object_id")
  if (_store_object_id == null || _store_object_id == "" || _store_object_id == "undefined")
  {
    return false
  }
  else
  {
    return _store_object_id
  }
}

export function getCustomStoreConfig()
{
  for (let i = 0; i < stores_data.length; i++)
  {
    if (window.location.href.search(stores_data[i].domain + ".zamow.org") != -1 || window.location.href.search(stores_data[i].domain + ".pl") != -1 || window.location.href.search(stores_data[i].domain + ":3000") != -1 || window.location.href.search(stores_data[i].domain + ".ziofood.pl") != -1)
    {
      if (stores_data[i].user_object_id)
      {
        window.localStorage.setItem("custom_store_user_object_id", stores_data[i].user_object_id);
      }
      else
      {
        window.localStorage.setItem("custom_store_object_id", stores_data[i].store_object_id);
      }

      return stores_data[i]
      break
    }
  }
  return {}
}

export function getReturnUrl()
{
  if (isCustomStoreAvailable())
  {
    return window.location.protocol + "//" + window.location.host + "/thankyou"
  }
  else
  {
    return "https://ziofood.pl/thankyou"
  }
}

export function getReturnErrorUrl()
{
  if (isCustomStoreAvailable())
  {
    return window.location.protocol  + "//" + window.location.host + "/paymenterror"
  }
  else
  {
    return "https://ziofood.pl/paymenterror"
  }
}

export function canAddProductToBasket()
{
    if ( ( (isUserAddressAdded() && getCurrentStoreDataKey("delivery_type").delivery) && isLoggedIn() ) || ( getCurrentStoreDataKey("delivery_type").delivery === false && isLoggedIn() ) )
    {
        return true
    }

    return false
}