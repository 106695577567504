import styled from "styled-components";
import {Title} from "../styles/text";

export const StoreGrid = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(400px, 400px));
	gap: 16px
`

export const StoreName = styled.div`
	font-size: 16px;
	margin-top: 8px;
	padding-left: 30px;	
	width: 100%;
	margin-left: -16px;
`

export const StoreExtraInfo = styled.div`
	font-size: 12px;
	margin-top: 6px;
	padding-left: 30px;	
	width: 100%;
	margin-left: -16px;
	color: grey;
`

export const Store = styled(Title)`
	height: 208px;
	width: 400px;
	border-radius:8px;
	box-shadow: 0px 0px 0px 0px grey;
	transition: .3s;
	&:hover {
		cursor: pointer;
		box-shadow: 0px 0px 8px 0px grey;
	}
	background-color: white;
`

export const StoreImage = styled.div`
	height: 132px;
	padding: 10px;
	border-radius:8px;
	background-image: ${({image}) => `url(${image});` }
	opacity: ${({is_open}) => (is_open ? `1` : `0.5` ) };
	background-position: center;
	background-size: cover;
	z-index: 999;
`