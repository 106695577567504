import styled from "styled-components"
import { withStyles } from '@material-ui/core/styles';
import {ProductPrice} from "../components/menu"
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';


const MyCheckbox = withStyles({
  root: {
    '&$checked': {
      color: "#8363B7"//"#FBAC19",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const MyRadio = withStyles({
  root: {
    '&$checked': {
      color: "#8363B7"
    },
  },
  checked: {},
})((props) => <Radio  color="default" {...props} />);

const Grid = styled.div`
	display: grid;
	grid-template-columns: 3fr 1fr;
	margin-bottom: 6px;
`

const Label = styled.div`
	cursor: pointer;
	margin-right: 8px;
`

const Price = styled(ProductPrice)`
	text-align: right;
	padding: 0;
	margin: 0;
	margin-right: 24px;
	margin-top: 4px;
`

export function ProductOption({option, addOption})
{
	return <Grid onClick={(e)=>{ addOption(e, option)}}>
		<Label>
			<MyCheckbox 
				style = {{marginLeft:"-10px"}}
				checked={option.checked} 
				id={option.uid} 
				
				size="small"
			/>
				{option.name}

		</Label>
		<Price>{option.price} zł</Price>
	</Grid>
}

export function ProductOptionRequired({option, index, addOption})
{
	return <Grid onClick={(e)=>{ addOption(option, index) }}>
		<Label>
			<MyRadio 
				style = {{marginLeft:"-10px"}}
				id={option.uid} 
				value={option} 
				name="option_required"
				label={option.name} 
				onChange={()=>{}}
				checked={option.checked}
				size="small"
				color="default"
			/>	
			{option.name}
		</Label>
		<Price>{option.price} zł</Price>
	</Grid>
}