

export function getPromocodeDiscount(promocode, delivery_type, order_value, delivery_price)
{
	let _discount = 0
	if (promocode)
	{
		if (promocode.delivery_type === "all" || promocode.delivery_type === delivery_type)
		{
			if (promocode.discount_target === "order")
			{
				if (delivery_type === "delivery" || delivery_type === "collect")
				{
					if (promocode.discount_type === "percent")
					{
						_discount = order_value*promocode.value
					} 
					else if (promocode.discount_type === "value")
					{
						_discount = promocode.value
					}
				}
			} 
			else if (delivery_type === "delivery" && promocode.discount_target === "delivery" && delivery_price > 0)
			{
				if (promocode.discount_type === "percent") 
				{
					_discount = delivery_price*promocode.value
				}
				else if (promocode.discount_type === "value")
				{
					_discount = promocode.value
				} 
				
			} 
		}
	}
	return _discount
}