import Parse from "parse"
import React, {useState, useEffect} from 'react';
import { useHistory  } from "react-router-dom"
import { Navbar, NavbarSpace } from "../components/navbar"
import { Container  } from '@material-ui/core';
import styled from "styled-components"
import Button from '@material-ui/core/Button';
import DoneIcon from '@material-ui/icons/Done';
import {color_accent} from "../styles/globalstyle"

const TextContainer = styled.div`
    text-align: center;
    margin-top: 80px;
    box-shadow: 0px 0px 2px 0px rgb(0,0,0,.1);
    border-radius:6px;
    padding: 16px;
`

const Title = styled.h1`
    font-size: 60px;
    color:${color_accent};
`

const ButtonContainer = styled.div`
    margin-top: 60px;
    padding-bottom: 40px;
`


export default function ThankYou() {

    const history = useHistory();

    function goTo()
    {
        if(navigator.userAgent.toLowerCase().indexOf("android") > -1)
        {
             window.location.href = 'https://play.google.com/store/apps/details?id=pl.zioapp.customer';
        }
        else if(navigator.userAgent.toLowerCase().indexOf("iphone") > -1)
        {
             window.location.href = 'https://apps.apple.com/pl/app/id1511807312';
        }
    }

    useEffect(() => {

    }, [])

  return (
    <>
        <img
            src='../images/mobile-info.jpeg'
            alt="mobile app information banner"
            style={{ width: "100%", height: "100%" }}
            onClick={()=>{goTo()}}
        />
    </>
  )
}

