import { makeStyles } from '@material-ui/styles';
import {useState} from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles({
  topScrollPaper: {
    alignItems: 'flex-start',
  },
  topPaperScrollBody: {
    verticalAlign: 'top',
  },
})

export function AlertDialog({alert, setAlert}) {

  const handleClose = () => {
    if (alert.callback)
    {
      alert.callback()
    }
    setAlert({open:false, title:"", description:""});
  };

  return (
    <Dialog
      open={alert.open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{alert.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {alert.description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export function YesNoDialog({open, setOpen, onYes, title, description}) {

  function handleClose()
  {
    setOpen(false);
  };

  function handleClosePositive()
  {
    setOpen(false);
    onYes();
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>
          Nie
        </Button>
        <Button onClick={handleClosePositive} color="primary" autoFocus>
          Tak
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export function AddressInput({title, open, handleClickOpen, handleClose, handleSave, onChange}) 
{
  const classes = useStyles()
  return (
      <Dialog 
        open={open} 
        onClose={handleClose} 
        aria-labelledby="form-dialog-title" 
        maxWidth="xs"
        classes={{
          scrollPaper: classes.topScrollPaper,
          paperScrollBody: classes.topPaperScrollBody,
        }}>
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>
        <form autoComplete="off" >
          <TextField
            autoFocus
            margin="dense"
            id="city"
            label="miasto"
            type="text"
            onChange={onChange}
            fullWidth
          />
          <TextField
            margin="dense"
            id="street"
            label="ulica"
            type="text"
            onChange={onChange}
            fullWidth
          />
          
          <TextField
            margin="dense"
            id="building"
            label="nr budynku"
            type="text"
            onChange={onChange}
            fullWidth
          />
          <TextField
            margin="dense"
            id="flat"
            label="nr mieszkania"
            type="text"
            onChange={onChange}
            fullWidth
          />
         </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            anuluj
          </Button>
          <Button onClick={handleSave} color="primary">
            zapisz
          </Button>
        </DialogActions>
      </Dialog>

  );
}

export function AddressSelectDialog({title, items, onSelect}) 
{
  const classes = useStyles()
  const [open, setOpen] = useState(true);
  const handleClose = () => {
    setOpen(false);
  };
  const handleSelect = (value) => {
    setOpen(false);
    onSelect(value)
    console.log(value)
  };

  return (
      <Dialog 
        open={open} 
        onClose={handleClose} 
        aria-labelledby="form-dialog-title" 
        maxWidth="xs"
        classes={{
          scrollPaper: classes.topScrollPaper,
          paperScrollBody: classes.topPaperScrollBody,
        }}>
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <List component="div" role="list">
            {items.map(address => (
              <ListItem button divider role="listitem" onClick={()=>{handleSelect(address)} } key={address.id}>
              <ListItemText primary={address.city + ", " + address.street + " " + address.building_number}  />
            </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            anuluj
          </Button>
        </DialogActions>
      </Dialog>
  
  );
}


export function SingleInput({title, paceholder, value, inputType, maxlength, open, handleClickOpen, handleClose, handleSave, onChange}) 
{
  const classes = useStyles()
  return (
    <div>
      <Dialog 
        open={open} 
        onClose={handleClose} 
        aria-labelledby="form-dialog-title"
        classes={{
          scrollPaper: classes.topScrollPaper,
          paperScrollBody: classes.topPaperScrollBody,
        }}>
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <form autoComplete="off" onSubmit={(event) => {
            event.preventDefault();
            handleClose();
          }} >
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label={paceholder}
              type={inputType?inputType:"text"}
              fullWidth
              onChange={onChange}
              value={value?value:""}
            />
            </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            anuluj
          </Button>
          <Button onClick={handleSave} color="primary">
            zapisz
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export function StoreRegulationDialog({open, setOpen, content, callback}) 
{
  function handleClose()
  {
    setOpen(false);
    if (callback)
    {
      callback()
    }
  };

  return (
    <div>
      <Dialog 
        open={open} 
        onClose={handleClose} 
        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Regulamin</DialogTitle>
        <DialogContent dividers={true}>
           <DialogContentText
            id="scroll-dialog-description"
            tabIndex={-1}
          >
          {content.map(item => (
              <p>{item.text}</p>
            ))}
          
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}