import {color_accent} from "../styles/globalstyle"

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Link from '@material-ui/core/Link'
import { NavbarSpace } from "../components/navbar"


export function FooterZio() {
	return <>
		<NavbarSpace/>
		<Box px={{xs: 3, sm: 10}} py={{ xs: 5, sm: 10}} bgcolor="text.secondary" color="white">
			<Container maxWidth="lg">
				<Grid container spacing={5}>
					<Grid item xs={12} sm={4}>
						<Box borderBottom={1}>Dla biznesu</Box>
						<Box>
							<Link href="https://www.web.ziofood.pl/pl/dolacz-do-nas/dolacz-do-nas-formularz/" color="inherit" target="_blank" rel="noopener">
								Dodaj restaurację
							</Link>
						</Box>
						<Box>
							<Link href="https://www.web.ziofood.pl/pl/wspolpraca/dlaczego-warto-dolaczyc-do-zio-app/" color="inherit" target="_blank" rel="noopener">
								Dlaczego warto dołączyć?
							</Link>
						</Box>
					</Grid>
					<Grid item xs={12} sm={4}>
						<Box borderBottom={1}>ZiO</Box>
						<Box>
							<Link href="https://www.web.ziofood.pl/pl/o-nas/nasza-misja/" color="inherit" target="_blank" rel="noopener">
								Nasza misja
							</Link>
						</Box>
						<Box>
							<Link href="https://www.web.ziofood.pl/pl/aktualnosci/" color="inherit" target="_blank" rel="noopener">
								Aktualności
							</Link>
						</Box>
						<Box>
							<Link href="https://www.web.ziofood.pl/pl/kontakt/" color="inherit" target="_blank" rel="noopener">
								Kontakt
							</Link>
						</Box>
					</Grid>
					<Grid item xs={12} sm={4}>
						<Box borderBottom={1}>Pomoc</Box>
						<Box>
							<Link href="https://www.web.ziofood.pl/pl/regulaminy/regulamin-skladania-zamowien-za-posrednictwem-aplikacji-zio-food/" color="inherit" target="_blank" rel="noopener">
								Regulamin
							</Link>
						</Box>
						<Box>
							<Link href="https://www.web.ziofood.pl/pl/regulaminy/polityka-prywatnosci-i-cookies/" color="inherit" target="_blank" rel="noopener">
								Polityka prywatności
							</Link>
						</Box>
					</Grid>
				</Grid>
				<Box textAlign="center" pt={{xs: 5, sm: 10}} pb={{xs: 5, sm: 0}} color={color_accent}>
					ZiO food {new Date().getFullYear()}
				</Box>
			</Container>
		</Box>
	</>
}

export function FooterCustom({storeName}) {
	return <>
		<NavbarSpace/>
		<Box px={{xs: 3, sm: 10}} py={{ xs: 5, sm: 10}} bgcolor="text.secondary" color="white">
			<Container maxWidth="lg">
				<Grid container spacing={5}>
					<Grid item xs={12} sm={4}>
					</Grid>
					<Grid item xs={12} sm={4}>
						<Box borderBottom={1}>Pomoc</Box>
						<Box>
							<Link href="https://www.web.ziofood.pl/media/a5nhoaou/regulamin-us%C5%82ugi-za-po%C5%9Brednictwem-aplikacji-zioapp.pdf" color="inherit" target="_blank" rel="noopener">
								Regulamin platformy ZiO food
							</Link>
						</Box>
						<Box>
							<Link href="/regulation" color="inherit" rel="noopener">
								Regulamin {storeName}
							</Link>
						</Box>
						<Box>
							<Link href="https://www.web.ziofood.pl/pl/regulaminy/polityka-prywatnosci-i-cookies/" color="inherit" target="_blank" rel="noopener">
								Polityka prywatności
							</Link>
						</Box>
					</Grid>
					<Grid item xs={12} sm={4}>
					</Grid>
				</Grid>
				<Box textAlign="center" pt={{xs: 5, sm: 10}} pb={{xs: 5, sm: 0}} color={color_accent}>
					{storeName} {new Date().getFullYear()} | wspierane przez <a href="https://www.web.ziofood.pl" >ZiO food</a>
				</Box>
			</Container>
		</Box>
	</>
}