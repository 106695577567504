import {useEffect} from 'react';
import { useHistory  } from "react-router-dom"
import { Navbar, NavbarSpace } from "../components/navbar"
import { Container  } from '@material-ui/core';
import styled from "styled-components"
import Button from '@material-ui/core/Button';
import {color_accent} from "../styles/globalstyle"

const TextContainer = styled.div`
    text-align: center;
    margin-top: 80px;
    box-shadow: 0px 0px 2px 0px rgb(0,0,0,.1);
    border-radius:6px;
    padding: 16px;
`

const Title = styled.h1`
    font-size: 60px;
    color:${color_accent};
`

const ButtonContainer = styled.div`
    margin-top: 60px;
    padding-bottom: 40px;
`


export default function ThankYou() {

    const history = useHistory();

    function goToOrders()
    {
        history.push('/orders')
    }

    useEffect(() => {
        document.title = "dziękujemy"  
    }, [])

  return (
    <>
        <Navbar showBack={true} showIcon={true} showAddressBar={true} showUserOptions={true} />
        <NavbarSpace/>
        <Container>
            <TextContainer>
                <Title>Program lojalnościowy ZiO food</Title>
                <h1>Za każde 10zł dostaniesz 1 punkt.</h1>
                <h4>Zamawiaj w ZiO food z płatnością online i zbieraj punkty!</h4>
                <h4>Punkty wymieniaj na wartościowe nagrody.</h4>
                <h4>Punkty naliczają się przy każdej transakcji z płatnością online zarówno przez stronę www jak i aplikację mobilną.</h4> 
                <h4>Odbiór nagród jest dostępny w aplikacji mobilnej. Zaloguj się na swoje konto w aplikacji na iOS lub Android i odbierz nagrody.</h4> 
                <h2>Dziękujemy i zapraszamy</h2>
            </TextContainer>
        </Container>
    </>
  )
}

