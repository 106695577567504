
export function getStatusOnDeliveryDistance(store_delivery_information, store_delivery_type, store_delivery_distance)
{
  if (!store_delivery_information) { return "error"}

  const lat = localStorage.getItem("primary_address_latitude")
  const lon = localStorage.getItem("primary_address_longitude")
  
  if (!store_delivery_type.delivery)
    { 
      return "not_available"
    }
  else if (lat == null || lon == null)
    {
      return "address_missing"
    }
  else if (store_delivery_information && store_delivery_information.distance && store_delivery_distance && store_delivery_distance > store_delivery_information.distance)
    {
      return "too_far"
    }
  else
    {
      return "ok"
    }
};

export function getDeliveryPrice(store_delivery_information, store_delivery_distance)
{
  let _price = store_delivery_information.price
  let _minimum_order = store_delivery_information.minimum_order
  if (store_delivery_information.zones) // update delivery price based on distance 
  {
    store_delivery_information.zones.sort( function( a,b ) { if (a.distance && b.distance) { return Number(a.distance) - Number(b.distance) } } )

    for (let i = 0; i < store_delivery_information.zones.length; i++)
    {
      if (store_delivery_distance <= store_delivery_information.zones[i].distance)
      {
        _price = store_delivery_information.zones[i].price
        if (store_delivery_information.zones[i].minimum_order !== null)
        {
          _minimum_order = store_delivery_information.zones[i].minimum_order
        } 
        break
      } 
    }
  }
  return {price: _price, minimum_order: _minimum_order}
}





