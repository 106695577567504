import { useState} from "react";

function getCurrentTime()
{
  const date = new Date();
  const timeNow = Math.floor( date.getTime()*0.001 );
  return timeNow
}

export function useBasket(initialValue) 
{
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [basket, setBasketHook] = useState(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem("basket");
      // Parse stored json or if none return initialValue
      // console.log(item)
      return item ? JSON.parse(item) : [];
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return [];
    }
  });
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setBasket = (value) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(basket) : value;
      // Save state
      setBasketHook(valueToStore);
      // Save to local storage
      window.localStorage.setItem("basket", JSON.stringify(valueToStore));
      
      window.localStorage.setItem("basket_update_timestamp", getCurrentTime())
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };
  return {basket, setBasket};
}

export function canAddUniqueToBasket(basket,  item)
{
  let can_add = true
  for (var i = 0; i < basket.length; i++) 
    {
    if (basket[i].id === item.id)
    {
      can_add = false
    }
  }
  return can_add
}

export function clearBasket()
{
  window.localStorage.removeItem("basket")
  window.localStorage.removeItem("basket_update_timestamp")  
}

export function initBasket()
{
  if ( (Number(getCurrentTime()) - 1200) > Number(window.localStorage.getItem("basket_update_timestamp")) )
  {
    clearBasket()
  }
}