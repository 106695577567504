import Parse from "parse"
import React, {useState, useEffect} from 'react';
import { useHistory  } from "react-router-dom"
import { IsStoreOpen } from "../services/store"
import { getStatusOnDeliveryDistance, getDeliveryPrice } from "../services/delivery"
import { getDistance } from "../services/helper"
import { clearBasket } from "../services/basket"
import { Logout } from "../services/user"
import { Container  } from '@material-ui/core';
import { Stores } from "../components/stores"
import { CustomNavbar, NavbarSpace } from "../components/navbar"
import {HomeProducts} from "../components/home_products"
import { FooterZio } from "../components/footer"


function getStoreLogo(stores, store_object_id)
{
  for (let i = 0; i < stores.length; i++) 
  {
    if (stores[i].id === store_object_id)
    {
      return stores[i].logo_image_url
    }
  }
}


async function fetchData(setStoresOpenAndDeliver, setClosedStores, setStoresOpenAndTooFar, lat, lon, history, setLists) {

      const distance = 10000;
      let location_is_available = false

      const Store = Parse.Object.extend("Store");
      const query = new Parse.Query(Store);
      if (lat != null && lon != null)
      {
        const location = new Parse.GeoPoint(parseFloat(lat), parseFloat(lon));
        query.withinKilometers("location", location, distance, true);
        console.log("no address")
        location_is_available = true
      }
      query.equalTo("isActive", true)
      query.equalTo("platform", "web")
      query.equalTo("userObjectId", window.localStorage.getItem("custom_store_user_object_id"))
      query.limit(50)

      const results = await query.find().catch((e) => {
        if (e.code === 209)
        {
          Logout()
          history.push('/start')
        }
      });

      if (results && results.length)
      {
        let stores_opened_and_delivery = [];
        let stores_opened_and_delivery_ids = []
        let stores_opened_and_too_far = []
        let stores_closed = []

        for (let i = 0; i < results.length; i++) 
        {
          if (location_is_available)
          {
            const store_location = results[i].get("location")
            let _location = {}
            if (results[i].get("deliveryInformation").location)
            {
               _location = results[i].get("deliveryInformation").location
            } 
            else
            {
              _location = store_location
            }
              
            results[i].delivery_distance = getDistance({ latitude: lat, longitude: lon },{ latitude: _location.latitude, longitude: _location.longitude  }  )
            results[i].store_distance = getDistance({ latitude: lat, longitude: lon },{ latitude: store_location.latitude, longitude: store_location.longitude  }  )
            const features = results[i].get("features")
            let feature_zero_waste = false
            if (features)
            {
              if (features.zero_waste)
              {
                feature_zero_waste = true
              }
            }
            if ( IsStoreOpen(results[i].get("isOpen"), results[i].get("openingHours") ) )
            {
              if (getStatusOnDeliveryDistance(results[i].get("deliveryInformation"), results[i].get("deliveryType"), results[i].delivery_distance) === "ok")
              {
                stores_opened_and_delivery.push({
                    id:results[i].id,
                    name:results[i].get("name"),
                    image_url:results[i].get("imageUrlAdmin").url(),
                    image_small_url:results[i].get("imageSmallUrlAdmin").url(),
                    logo_image_url:results[i].get("logoUrlAdmin").url(),
                    is_open:true,
                    distance: results[i].store_distance,
                    delivery_price: getDeliveryPrice(results[i].get("deliveryInformation"), results[i].store_distance).price,
                    zero_waste: feature_zero_waste
                })
                stores_opened_and_delivery_ids.push(results[i].id)
              }
              else 
              {
                stores_opened_and_too_far.push({
                    id:results[i].id,
                    name:results[i].get("name"),
                    image_url:results[i].get("imageUrlAdmin").url(),
                    image_small_url:results[i].get("imageSmallUrlAdmin").url(),
                    logo_image_url:results[i].get("logoUrlAdmin").url(),
                    is_open:true,
                    distance: results[i].store_distance,
                    delivery_price: getDeliveryPrice(results[i].get("deliveryInformation"), results[i].store_distance).price,
                    features: feature_zero_waste
                })
              }
            }
            else
            {
              stores_closed.push({
                  id: results[i].id,
                  name: results[i].get("name"),
                  image_url: results[i].get("imageUrlAdmin").url(),
                  image_small_url:results[i].get("imageSmallUrlAdmin").url(),
                  logo_image_url: results[i].get("logoUrlAdmin").url(),
                  is_open: false,
                  distance: results[i].store_distance,
                  delivery_price: getDeliveryPrice(results[i].get("deliveryInformation"), results[i].store_distance).price,
                  features: feature_zero_waste
              })
            }
          }
          else
          {
            stores_opened_and_delivery.push({
                id:results[i].id,
                name:results[i].get("name"),
                image_url:results[i].get("imageUrlAdmin").url(),
                image_small_url:results[i].get("imageSmallUrlAdmin").url(),
                logo_image_url:results[i].get("logoUrlAdmin").url(),
                is_open:true,
                distance: results[i].store_distance,
                delivery_price: getDeliveryPrice(results[i].get("deliveryInformation"), results[i].store_distance).price,
                zero_waste: false
            })
          }

        }
        setStoresOpenAndDeliver(stores_opened_and_delivery);
        setStoresOpenAndTooFar(stores_opened_and_too_far);      
        setClosedStores(stores_closed);
      }
}

export default function Home() {
  const [storesOpenAndDeliver, setStoresOpenAndDeliver] = useState([])
  const [storesOpenAndTooFar, setStoresOpenAndTooFar] = useState([])
  const [storesClosed, setClosedStores] = useState([])
  const [searchResults, setSearchResults] = useState([])
  const [searchPhrase, setSearchPhrase] = useState([])
  const [lists, setLists] = useState([])
  const history = useHistory();


  const lat = localStorage.getItem("primary_address_latitude")
  const lon = localStorage.getItem("primary_address_longitude")
  // const router = useRouter();


  useEffect(() => {
    fetchData(setStoresOpenAndDeliver, setClosedStores, setStoresOpenAndTooFar, lat, lon, history, setLists)
    document.title = "lista lokali"   
    clearBasket()   
  }, [])

  

  function GoToStore(store_object_id)
  {
    localStorage.setItem("current_store_object_id", store_object_id)
    localStorage.setItem("custom_store_object_id", store_object_id)
    history.push("/store/"+store_object_id)
  }


  return (
    <>
        <CustomNavbar showBack={false} showAddressBar={false} storeData={{logo_image_url:localStorage.getItem("custom_store_logo_image_url")}} tabIndex={3} />
        <NavbarSpace/>
        <Container maxWidth="lg" style={{minHeight:"1200px"}}>
            {lat&&lon?<h2>Otwarte</h2>:<h2>Wybierz lokal</h2>}
            <Stores data = { storesOpenAndDeliver } goToStore = { GoToStore } key_name = {"opened"} />
            {storesOpenAndTooFar.length>0?<h2>Otwarte | tylko odbiór</h2>:null}
            <Stores data = { storesOpenAndTooFar } goToStore = { GoToStore } key_name = {"out_of_range"} />
            {storesClosed.length>0?<h2>Zamknięte</h2>:null}
            <Stores data = { storesClosed } goToStore = { GoToStore } key_name = {"closed"} />
        </Container>
        <FooterZio/>
    </>
    )

}

