import Parse from "parse"
import React, {useState, useEffect} from 'react';
import { IsStoreOpen, OpeningTimeInfo, SetCurrentStoreData, setDefaultStore, isStoreWithinRange } from "../services/store"
import { Container, Grid  } from '@material-ui/core';
import { useHistory  } from "react-router-dom"
import { Navbar, NavbarSpace, CustomNavbar } from "../components/navbar"
import { StoreInfoName, StoreInfoDelivery, StoreInfoCollect, StoreInfoContact, StoreDescription } from "../components/store"
import { Banner } from "../components/banner"
import { Menu } from "../components/menu"
import { ProductDialog } from "../components/product_dialog"
import { useBasket, canAddUniqueToBasket, clearBasket } from "../services/basket"
import { getDeliveryPrice } from "../services/delivery"
import { getDistance, isMobile} from "../services/helper"
import { v4 as uuidv4 } from 'uuid';
import { FooterZio, FooterCustom } from "../components/footer"
import { isUserAddressAdded, getUserObjectId, isLoggedIn } from "../services/user"

// import TagManager from 'react-gtm-module'

function updateCategoryDataWithCount(categoriesData, product_id)
{
    for (let i = 0; i < categoriesData.length; i++)
    {
        for (let j = 0; j < categoriesData[i].products.length; j++)
        {
            if (categoriesData[i].products[j].id === product_id )
            {
                categoriesData[i].products[j].quantity = categoriesData[i].products[j].quantity + 1
            }
        }
    }
}

function updateCategoryDataWithQuantity(categoriesData, product_id, quantity)
{
    for (let i = 0; i < categoriesData.length; i++)
    {
        for (let j = 0; j < categoriesData[i].products.length; j++)
        {
            if (categoriesData[i].products[j].id === product_id )
            {
                categoriesData[i].products[j].quantity = quantity
                break
            }
        }
    }
}

function mapProductItem(product, store, options)
{
    let _small_image_url = ""
    let _large_image_url = ""
    let _image_opacity = 1
    if (product.get("imageSmallUrlAdmin") != null && product.get("imageUrlAdmin") != null)
    {
        _small_image_url = product.get("imageSmallUrlAdmin").url()
        _large_image_url = product.get("imageUrlAdmin").url()
    }

    let promo_item = false 
    let show_item = true 
    let highlight = false
    let quantity = 0
    if (product.get("tag") == "promotion")
    {
        show_item = false
        promo_item = true
        highlight = true
        quantity = 1
    }

    if (product.get("tag") == "free_delivery")
    {
        promo_item = true
        highlight = true
        quantity = 1
    }

    
    let _diet = {}
    const product_diet = product.get("diets")
    if (product_diet)
    {
        for (let i = 0; i < product_diet.length; i++)
        {
            if (product_diet[i] == "vegan")
            {
                _diet.vegan = true
            }
            else if (product_diet[i] == "vegetarian")
            {
                _diet.vegetarian = true
            }
            else if (product_diet[i] == "glutenfree")
            {
                _diet.glutenfree = true
            }
        }
    }

    let _weight = product.get("weight")
    if (_weight && _weight > 0)
    {
        _weight = _weight/1000
    }

    return {
        name: product.get("name"),
        description: product.get("description"),
        price: product.get("price"),
        original_price: product.get("originalPrice"),
        small_image_url:_small_image_url,
        large_image_url: _large_image_url,
        id: product.id,
        image_opacity: _image_opacity,
        quantity: 0,
        promo_item:promo_item,
        show_item: show_item,
        modifiers: product.get("modifiers"),
        options:options,
        tag: product.get("tag"),
        rule: product.get("rule"),
        promotion_tag: product.get("promotionTag"),
        category_object_id: product.get("categoryObjectId"),
        stock: product.get("stock"),
        diets: _diet,
        spice_level: product.get("spiceLevel"),
        allergen: product.get("allergen"),
        markings: product.get("markings"),
        highlight: highlight,
        weight: _weight
    }
}

function getOptionsForProductStep2(options_object_ids, parent_id, products)
{
    let _options = []
    for (var i = 0; i < options_object_ids.length; i++) 
    {
        for (var j = 0; j < products.length; j++) 
        {
            if (products[j].get("tag") === "variant" && options_object_ids[i] === products[j].id)
            {
                _options.push(
                    {
                        name: products[j].get("name"),
                        price: products[j].get("price"),
                        id: products[j].id,
                        parent_id: parent_id,
                        uid: uuidv4(),
                        modifiers: products[j].get("modifiers"),
                        checked:false
                    })
            }
        }
    }

    return _options
}

function getOptionsForProduct(product, products)
{
    let _options = []
    if (product.get("options") != null)
    {
        const _product_options = product.get("options")
        if (_product_options.length > 0)
        {
            for (var i = 0; i < _product_options.length; i++) {
                for (var j = 0; j < products.length; j++) {
                    if (products[j].get("tag") === "variant_group" && _product_options[i] === products[j].id)
                    {
                        _options.push({
                            name: products[j].get("name"),
                            rule: products[j].get("rule"),
                            id: products[j].id,
                            items:getOptionsForProductStep2(products[j].get("options"), products[j].id, products)
                        })
                    }
                }
            }
        }
    }

    return _options
}

async function fetchData(setStoreData, setCategoriesData, setDeliveryPrice, setFreeDeliveryFrom, setMinimumOrderValue, basket, setBasket, store_object_id, setStoreWithinRange, setCollectWaitingTime, setDeliveryWaitingTime, setHighTraffic, defaultZioUi) 
{
    const _store_object_id = store_object_id?store_object_id:localStorage.getItem("current_store_object_id")
    localStorage.setItem("current_store_object_id", _store_object_id)

    const timestamp = Date.now()

    const Store = Parse.Object.extend("Store");
    const queryStore = new Parse.Query(Store);
    const resultStore = await queryStore.get(_store_object_id)
    const store_location = resultStore.get("location")
    const user_lat = localStorage.getItem("primary_address_latitude")
    const user_lon = localStorage.getItem("primary_address_longitude")
    const delivery_information = resultStore.get("deliveryInformation")

    const store_misc = resultStore.get("misc")
    let stock_source = _store_object_id
    let _collect_waiting_time_for_store_data = ""
    let _delivery_waiting_time_for_store_data = ""

    if (defaultZioUi === true && store_misc.redirect_if_mobile && store_misc.redirect_if_mobile !== "")
    {
        if (isMobile())
        {
            window.location.href = store_misc.redirect_if_mobile
        }
    }


    if (store_misc)
    {
        if (store_misc.stock_source && store_misc.stock_source != "")
        {
            stock_source = store_misc.stock_source
        } 

        if (store_misc.waiting_time)
        {
            if (store_misc.waiting_time.delivery_is_active)
            {
                const _delivery_waiting_time = resultStore.get("deliveryWaitingTime")
                if (_delivery_waiting_time)
                {
                    _delivery_waiting_time_for_store_data = (_delivery_waiting_time/60).toString() + "-" + ( (_delivery_waiting_time + store_misc.waiting_time.delivery_offset)/60).toString()
                    setDeliveryWaitingTime(_delivery_waiting_time_for_store_data)
                }
            }

            if (store_misc.waiting_time.collect_is_active)
            {
                const _collect_waiting_time = resultStore.get("collectWaitingTime")
                if (_collect_waiting_time)
                {
                    _collect_waiting_time_for_store_data = (_collect_waiting_time/60).toString() + "-" + ( (_collect_waiting_time + store_misc.waiting_time.collect_offset) /60).toString()
                    setCollectWaitingTime(_collect_waiting_time_for_store_data)
                }
            }

            if (store_misc.waiting_time.collect_is_active || store_misc.waiting_time.delivery_is_active)
            {
                const high_traffic = resultStore.get("highTraffic")
                if (high_traffic !== null)
                {
                    setHighTraffic(high_traffic)
                }
            }
        }
    }

    if (delivery_information.free_from_order_value && delivery_information.free_from_order_value.is_active === true && delivery_information.free_from_order_value.value)
    {
        setFreeDeliveryFrom(delivery_information.free_from_order_value.value)
    }
    let _location = {}
    if (delivery_information.location)
    {
       _location = delivery_information.location
    } 
    else
    {
      _location = store_location
    }
    resultStore.delivery_distance = getDistance({ latitude: user_lat, longitude: user_lon },{ latitude: _location.latitude, longitude: _location.longitude  }  )
    resultStore.store_distance = getDistance({ latitude: user_lat, longitude: user_lon },{ latitude: store_location.latitude, longitude: store_location.longitude  }  )
    const _delivery_data = getDeliveryPrice(delivery_information, resultStore.store_distance)
    const delivery_type = resultStore.get("deliveryType")

    SetCurrentStoreData({
        name:resultStore.get("name"),
        object_id:resultStore.id,
        store_user_object_id:resultStore.get("userObjectId"),
        delivery_type: delivery_type,
        completion_type: resultStore.get("completionType"),
        delivery_information: delivery_information,
        location: resultStore.get("location"),
        address_information: resultStore.get("addressInformation"),
        delivery_price: _delivery_data.price,
        minimum_order_value: _delivery_data.minimum_order,
        distance: resultStore.store_distance,
        payment_method: resultStore.get("paymentMethod"),
        store_type: resultStore.get("storeType"),
        collect_waiting_time: _collect_waiting_time_for_store_data,
        delivery_waiting_time: _delivery_waiting_time_for_store_data,
        misc: store_misc
    })
    setStoreData({
        name:resultStore.get("name"),
        image_url: resultStore.get("imageUrlAdmin").url(),
        logo_image_url: resultStore.get("logoUrlAdmin").url(),
        address: resultStore.get("addressInformation").street + " " + resultStore.get("addressInformation").building_number + ", " + resultStore.get("addressInformation").city,
        phone_number: resultStore.get("addressInformation").phone_number,
        delivery_is_available: delivery_type.delivery,
        opening_hours: OpeningTimeInfo(resultStore.get("isOpen"), resultStore.get("openingHours")),
        is_open: IsStoreOpen(resultStore.get("isOpen"), resultStore.get("openingHours") ),
        delivery_price: _delivery_data.price,
        minimum_order_value: _delivery_data.minimum_order,
        description_custom: store_misc.description_custom?store_misc.description_custom:null,
        description: store_misc.description?store_misc.description:null,
        misc: store_misc
    })
    // set url for custom store reference
    localStorage.setItem("custom_store_logo_image_url", resultStore.get("logoUrlAdmin").url())

    setDeliveryPrice(_delivery_data.price)
    setMinimumOrderValue(_delivery_data.minimum_order)

    document.title = resultStore.get("name")

    const Category = Parse.Object.extend("Category");
    const queryCategory = new Parse.Query(Category);
    queryCategory.equalTo("storeObjectId", stock_source)
    queryCategory.equalTo("isActive", true)
    queryCategory.ascending("sortIndex");
    const resultsCategory = await queryCategory.find()

    const Product = Parse.Object.extend("Product");
    const queryProduct = new Parse.Query(Product);
    queryProduct.equalTo("storeObjectId", stock_source)
    queryProduct.equalTo("isActive", true)
    queryProduct.equalTo("status", 0) 
    queryProduct.notEqualTo("stock", 0)
    queryProduct.ascending("sortIndex");
    queryProduct.greaterThan("expirationDate", timestamp/1000)
    
    queryProduct.limit(300)
    const resultsProduct = await queryProduct.find()

    let categoriesData = []

    let _promo_products = []
    let _discount_products = []
    let _zero_waste_products = []
    let _dish_of_the_day_products = []
    let _recommended_products = []
    let _basket_temp = basket // make sure all promo items are added, so first update array and they assign it.

    for (let i = 0; i < resultsProduct.length; i++) {
        if("dish_of_the_day" === resultsProduct[i].get("categoryObjectId"))
        { 
            _dish_of_the_day_products.push(mapProductItem(resultsProduct[i], resultStore, getOptionsForProduct(resultsProduct[i], resultsProduct) ))
        }
        else if("daily_promo" === resultsProduct[i].get("categoryObjectId"))
        {
            const _item = mapProductItem(resultsProduct[i], resultStore, getOptionsForProduct(resultsProduct[i], resultsProduct) )
            if (_item.tag === "free_delivery")
            {
                setDeliveryPrice(0)
                if (canAddUniqueToBasket(basket, _item) === true )
                {
                    _basket_temp.push(_item)
                }
            }
            else if (_item.tag === "promotion")
            {
                if (canAddUniqueToBasket(basket, _item) === true )
                {
                    _basket_temp.push(_item)
                    
                }
            }
            
            if (resultsProduct[i].get("promotionTag") === "daily_promo")
            {
                _discount_products.push(_item)
            }
            else  
            {
                _promo_products.push(_item)
            }
        }
        else if ("zero_waste" === resultsProduct[i].get("categoryObjectId"))
        {
            _zero_waste_products.push(mapProductItem(resultsProduct[i], resultStore, getOptionsForProduct(resultsProduct[i], resultsProduct) ))
        }

        if (resultsProduct[i].get("isRecommended"))
        {
            _recommended_products.push(mapProductItem(resultsProduct[i], resultStore, getOptionsForProduct(resultsProduct[i], resultsProduct) ))
        }
    }
    setBasket(_basket_temp)
    if (_zero_waste_products.length > 0)
    {
        categoriesData.push({
            id:"zero_waste",
            name:"Kupuję ratuję",
            products:_zero_waste_products
        })
    }
    if (_dish_of_the_day_products.length > 0)
    {
        categoriesData.push({
            id:"dish_of_the_day",
            name:"Danie dnia",
            products:_dish_of_the_day_products
        })
    }
    if (_promo_products.length > 0)
    {
        categoriesData.push({
            id:"daily_promo",
            name:"Promocja dnia",
            products:_promo_products
        })
    }
    if (_discount_products.length > 0)
    {
        categoriesData.push({
            id:"discount_products",
            name:"Zniżka dnia",
            products:_discount_products
        })
    }
    
    if (_recommended_products.length > 0)
    {
        categoriesData.push({
            id:"recommended_products",
            name:"Polecane",
            products:_recommended_products
        })
    }


    for (let i = 0; i < resultsCategory.length; i++) {
        let _products = []
        for (let j = 0; j < resultsProduct.length; j++) {
            if(resultsCategory[i].id === resultsProduct[j].get("categoryObjectId"))
            {
                let _small_image_url = ""
                if (resultsProduct[j].get("imageSmallUrlAdmin") != null && resultsProduct[j].get("imageUrlAdmin") != null)
                {
                    _small_image_url = resultsProduct[j].get("imageSmallUrlAdmin").url()
           
                }
                else 
                {
                    _small_image_url = resultStore.get("logoUrlAdmin").url()
                }
                _products.push(mapProductItem(resultsProduct[j], resultStore, getOptionsForProduct(resultsProduct[j], resultsProduct) ))

            }
        }
        if (_products.length > 0)
        {
            categoriesData.push({
                id:resultsCategory[i].id,
                name:resultsCategory[i].get("name"),
                products:_products
            })
        }
    }

    for (let i = 0; i < _basket_temp.length; i++) 
    {
        if (_basket_temp[i].quantity === 1)
        {
            updateCategoryDataWithCount(categoriesData, _basket_temp[i].id)
        }
        else  
        {
            updateCategoryDataWithQuantity(categoriesData, _basket_temp[i].id, _basket_temp[i].quantity)
        }
    }

    setCategoriesData(categoriesData)

    setStoreWithinRange(isStoreWithinRange())
}

export default function Store({ match }) 
{
    const [defaultZioUi, setDefaultZioUi] =  useState(localStorage.getItem("custom_store_object_id")===null||localStorage.getItem("custom_store_object_id")===""?true:false)
    const [storeData, setStoreData] = useState({"name":""})
    const [categoriesData, setCategoriesData] = useState([])
    const [deliveryPrice, setDeliveryPrice] = useState(0)
    const [freeDeliveryFrom, setFreeDeliveryFrom] = useState()
    const [storeWithinRange, setStoreWithinRange] = useState(true)
    const [userAddressAdded, setUserAddressAdded] = useState(true)
    const [collectWaitingTime, setCollectWaitingTime] = useState("")
    const [deliveryWaitingTime, setDeliveryWaitingTime] = useState("")

    const [minimumOrderValue, setMinimumOrderValue] = useState(0)
    const [highTraffic, setHighTraffic] = useState(false)

    
    const [product, setProduct] = useState()

    const basketHook = useBasket()//getBasket())
    const history = useHistory();


    useEffect(() => {
        function refreshData(e, store_id)
        {
            if (!store_id)
            {
                store_id = storeData.object_id
            }
            if (!document.hidden)
            {
                fetchData(setStoreData, setCategoriesData, setDeliveryPrice, setFreeDeliveryFrom, setMinimumOrderValue, basketHook.basket, basketHook.setBasket, store_id, setStoreWithinRange, setCollectWaitingTime, setDeliveryWaitingTime, setHighTraffic, defaultZioUi)
            }
        }

        if (!match.params.store_id)
        {
            if (match.params.custom_store_id) // user params for custom stores 
            {
                setDefaultZioUi(false)
                refreshData(null, match.params.custom_store_id)
                window.scrollTo(0,0)
            }
            else  
            { 
                if (localStorage.getItem("custom_store_object_id")) 
                {
                    match.params.store_id = setDefaultStore()//"ovoMnTcbF4"
                    setDefaultZioUi(false)
                    refreshData(null, match.params.store_id)
                    window.scrollTo(0,0)
                }
                else  // on first enter, there is no id so go to locations if they are available.
                {
                    history.push({
                       pathname: '/locations'
                    })
                }
            }
        }
        else  // standard ZiO
        {
            refreshData(null, match.params.store_id)
            window.scrollTo(0,0)
        }
        setUserAddressAdded(isUserAddressAdded())        

        if (typeof window !== 'undefined' && window.addEventListener) 
        {
            // window.removeEventListener('visibilitychange', refreshData)
            window.addEventListener('visibilitychange', refreshData, false)
        }
        return () => {
             window.removeEventListener('visibilitychange', refreshData)
           }
    },[])
  
    function GoToCheckout()
    {
        history.push({
           pathname: '/checkout'
        })
    }

    function notLoggedInCallback()
    {
        history.push({
           pathname: '/myaccount'
        })
    }

    function canAddProductToBasket() // to restrict some users.
    {
        if (storeData.is_open)
        {
            if (isLoggedIn())
            { 
                if (storeData.misc)
                {
                    if (storeData.misc.allow_users) // for przekaż dalej project. restricts only certain users to add to basket products.
                    {
                        const _user_object_id = getUserObjectId()
                        for (let i = 0; i < storeData.misc.allow_users.length; i++) 
                        {
                            if (storeData.misc.allow_users[i] === _user_object_id)
                            {
                                return true
                                break
                            }
                        }
                        alert("Nie masz uprawnień. Tylko uprawnieni użytkownicy mogą zamawiać w tym lokalu.");
                        return false
                    }
                    else  
                    {
                        return true
                    }
                }
                else 
                {
                    return true
                }
            }
            else  
            {
                notLoggedInCallback()
                return false
            }
        }
        else
        {
            return false
        }
    }

    function addProduct(product_data)
    {
        if (canAddProductToBasket())
        {
            if (product_data.stock === -1 || product_data.stock > product_data.quantity)
            {
                let add_new = true
                let quantity_label = 1
                let quantity = 1

                let _basket = [...basketHook.basket]
  
                if ( (product_data.modifiers && product_data.modifiers.length > 0) || (product_data.options && product_data.options.length > 0 )) // add new item if it has options or modifier.
                {
                    for (let i = 0; i < _basket.length; i++) 
                    {
                        if (_basket[i].id === product_data.id)
                        {
                            quantity_label = quantity_label + 1
                        }
                    }
                }
                else  
                {
                    for (let i = 0; i < _basket.length; i++) // if comes from + button, check if item exists, if yes, increade quantity. if not, kepp flag so it will be added as new product.
                    {
                        if (_basket[i].id === product_data.id)
                        {
                            _basket[i].quantity = _basket[i].quantity + 1
                            quantity_label = _basket[i].quantity
                            add_new = false
                        }
                    }
                }

                if (storeData.misc && storeData.misc.add_all_product_stock_to_basket && product_data.stock > 0) // spcecial setting for "przekaż dalej" project. add all item to basket at once.
                {
                    quantity = product_data.stock
                    quantity_label = product_data.stock
                }

                if (add_new === true)
                {
                    let _product = {
                        name: product_data.name,
                        id: product_data.id,
                        uid: uuidv4(),
                        description: product_data.description,
                        price: product_data.price,
                        quantity: quantity,
                        category_object_id: product_data.category_object_id,
                        show_item: product_data.show_item,
                        modifiers: product_data.modifiers,
                        modifier: product_data.modifier,
                        options: product_data.options,
                        selected_options: product_data.selected_options
                    }
                    basketHook.setBasket([...basketHook.basket, _product])
                    updateCategoryDataWithQuantity(categoriesData, product_data.id, quantity_label)
                }
                else  
                {
                    basketHook.setBasket(_basket)
                    updateCategoryDataWithQuantity(categoriesData, product_data.id, quantity_label)
                }
            }
        }
    }

    return (
        defaultZioUi?<>
        <Navbar showBack={true} showAddressBar={true} {...basketHook} GoToCheckout={GoToCheckout} showUserOptions={true} transparent={false} storeIsOpen={storeData.is_open}/>
        <NavbarSpace/>
        <ProductDialog product={product} setProduct={setProduct} {...basketHook} is_open={storeData.is_open} addProduct={addProduct} />
        <Container maxWidth="100%" style={{ minHeight:"330px", margin: "0px", padding: "0px"}}>
            {storeData.image_url?<img src={storeData.image_url} alt={storeData.name + " banner"} style={{ outline: "none", background:"none", backgroundColor: "transparent", border:"none", borderRadius:"0px 0px 6px 6px", border:"0px", width:"100%",  maxHeight : "512px", objectFit: "cover", opacity:1}} />:null}
        </Container>
        <Container maxWidth="lg" style={{ minHeight:"1200px"}}>
            <Grid container spacing={3} >
                <Grid item xs={12}>
                    <StoreInfoName name={storeData.name} opening_times={storeData.opening_hours} is_open={storeData.is_open} />
                    {storeData.description?<StoreDescription>{storeData.description}</StoreDescription>:null}
                </Grid>
                {storeData.delivery_is_available ? 
                    <Grid item sm={4} xs={12}>
                        <StoreInfoDelivery minimum_order_value={minimumOrderValue} delivery_price={deliveryPrice} free_delivery_from={freeDeliveryFrom} storeWithinRange={storeWithinRange} userAddressAdded={userAddressAdded} notLoggedInCallback={notLoggedInCallback} waitingTime={deliveryWaitingTime} highTraffic={highTraffic} />
                    </Grid>
                :null}
                <Grid item sm={4} xs={12}>
                    <StoreInfoCollect address={storeData.address} waitingTime={collectWaitingTime} highTraffic={highTraffic}  />
                </Grid>     
                <Grid item sm={4} xs={12}>
                    <StoreInfoContact phone_number={storeData.phone_number} />
                </Grid>     
            </Grid>
            <Menu data = {categoriesData} setOpenProduct = {setProduct} addProduct = {addProduct} notLoggedInCallback = {notLoggedInCallback} />
        </Container>
        <FooterZio/>
        </>:<>
            <CustomNavbar showBack={true} showAddressBar={false} {...basketHook} GoToCheckout={GoToCheckout} storeData={{logo_image_url:localStorage.getItem("custom_store_logo_image_url")}} tabIndex={0} storeIsOpen={storeData.is_open}/>
            <NavbarSpace/>
            <ProductDialog product={product} setProduct={setProduct} {...basketHook} is_open={storeData.is_open} addProduct={addProduct} />
            <Container maxWidth="100%" style={{ minHeight:"330px", margin: "0px", padding: "0px"}}>
                {storeData.image_url?<img src={storeData.image_url} alt={storeData.name + " banner"} style={{ outline: "none", background:"none", backgroundColor: "transparent", border:"none", borderRadius:"0px 0px 6px 6px", border:"0px", width:"100%",  height : "320px", objectFit: "cover", opacity:1}} />:null}
            </Container>
            <Container maxWidth="lg" style={{ minHeight:"1200px"}}>
                <Grid container spacing={3} >
                    <Grid item xs={12}>
                        <StoreInfoName name={storeData.name} opening_times={storeData.opening_hours} is_open={storeData.is_open} />
                        {storeData.description_custom?<StoreDescription>{storeData.description_custom}</StoreDescription>:null}
                        
                    </Grid>
                    {storeData.delivery_is_available ? 
                        <Grid item sm={4} xs={12}>
                            <StoreInfoDelivery minimum_order_value={minimumOrderValue} delivery_price={deliveryPrice} free_delivery_from={freeDeliveryFrom} storeWithinRange={storeWithinRange} userAddressAdded={userAddressAdded} notLoggedInCallback={notLoggedInCallback} waitingTime={deliveryWaitingTime} highTraffic={highTraffic} />
                        </Grid>
                    : null}
                    <Grid item sm={4} xs={12}>
                        <StoreInfoCollect address={storeData.address} waitingTime={collectWaitingTime} highTraffic={highTraffic}  />
                    </Grid>    
                    <Grid item sm={4} xs={12}>
                        <StoreInfoContact phone_number={storeData.phone_number} />
                    </Grid>    
                </Grid>
                <Menu data = {categoriesData} setOpenProduct = {setProduct} addProduct = {addProduct} notLoggedInCallback = {notLoggedInCallback} />
            </Container >
            <FooterCustom storeName={storeData.name} />
        </>
    )
}