import Parse from "parse";

export const addLoyaltyCard = async( ) =>
{
  const request_params = {
    target: "zio"
  }

  const result = await Parse.Cloud.run("addLoayltyCardForUser", request_params).catch(error => {
    console.log(error);
  });
  return result
}

 

export const getZioLoyaltyCardPoints = async( ) => {
    const _loyalty_card_object_id = localStorage.getItem("zio_loyalty_card_object_id")
    if (_loyalty_card_object_id)
    {
      const LoyaltyCardUser = Parse.Object.extend("LoyaltyCardUser");
      const queryLoyaltyCardUser = new Parse.Query(LoyaltyCardUser);
      const resultLoyaltyCardUser = await queryLoyaltyCardUser.get(_loyalty_card_object_id)     
      if (resultLoyaltyCardUser.id)
      {
        localStorage.setItem("zio_loyalty_card_points", resultLoyaltyCardUser.get("points"))
        return resultLoyaltyCardUser.get("points")
      }
    }
    else
    {
      const LoyaltyCardUser = Parse.Object.extend("LoyaltyCardUser");
      const queryLoyaltyCardUser = new Parse.Query(LoyaltyCardUser);
      queryLoyaltyCardUser.equalTo("target", "zio")
      const resultsLoyaltyCardUser = await queryLoyaltyCardUser.find()
      if (resultsLoyaltyCardUser[0])
      {
        if (!_loyalty_card_object_id)
        {
          localStorage.setItem("zio_loyalty_card_object_id", resultsLoyaltyCardUser[0].id)
        } 
        
        if (resultsLoyaltyCardUser[0].id)
        {
          localStorage.setItem("zio_loyalty_card_points", resultsLoyaltyCardUser[0].get("points"))
          return resultsLoyaltyCardUser[0].get("points")
        }
      }
      else // card is not defined. define it!
      {
        const result = await addLoyaltyCard()
        console.log(result)
        if (result.id)
        {
          return 1
        }
      }
    }
    return 0
};


