import styled from "styled-components"

export const Banner = styled.div`
	height: 200px;
	background-image: url("images/banner.jpg");
	background-position: center;
	background-size: cover;
	filter: contrast(75%)
`

export const StartBanner = styled.div`
	height: 100%;
	width: 100%;
	background-image: url("images/start_banner.jpg");
	background-position: center;
	background-size: cover;
	filter: brightness(70%);
	position:absolute ;
    z-index:-10;
`

// export const ThankYouBanner = styled.div`
// 	height: 1300px;
// 	width: 100%;
// 	background-image: url("images/thankyou_banner.jpg");
// 	background-position: center;
// 	background-size: cover;
// 	filter: brightness(70%);
// 	position:absolute ;
//     z-index:-10;
// `