import Parse from "parse";
import axios from "axios";

function updateLocalStorage(user_data)
{
    let _address = user_data.get("address")
    if (_address)
    {
      // console.log(_address)
      localStorage.setItem("primary_address_city", _address.city?_address.city:"")
      localStorage.setItem("primary_address_street", _address.street?_address.street:"")
      localStorage.setItem("primary_address_building_number", _address.building_number?_address.building_number:"")
      localStorage.setItem("primary_address_flat_number", _address.flat_number?_address.flat_number:"")
      localStorage.setItem("primary_address_phone_number", _address.phone_number?_address.phone_number:"")
      localStorage.setItem("primary_address_postcode", _address.postcode?_address.postcode:"")
      localStorage.setItem("primary_address_notes", _address.notes?_address.notes:"")
      localStorage.setItem("primary_address_longitude", _address.longitude?_address.longitude:0)
      localStorage.setItem("primary_address_latitude", _address.latitude?_address.latitude:0)
      localStorage.setItem("user_name", _address.user_name?_address.user_name:"")
    }
}

export const loginUser = async( username, password, callback ) => {
  if (username !== null && username !== "" && password !== null && password !== "")
  {
      try {
          const user_data = await Parse.User.logIn(username.toLowerCase(), password);
          // console.log(user_data)
          updateLocalStorage(user_data)
          
          // localStorage.setItem("user_token", user_data.getSessionToken())
          if (callback) 
          { 
            callback() 
            console.log("callback")
          }
          console.log("ok")
          return "ok"
      } catch (error) {
          console.log(error)
          return "error"
      }
  }
  else
  {
    let user_data = await Parse.User.current();
    // console.log(user_data)
    if (user_data) // get user  via api.
    {
      updateLocalStorage(user_data)
      if (callback) 
      { 
        callback() 
        console.log("callback") 
      }
      console.log("ok")
      return "ok"
    }
    else
    {
      console.log("no_credentials")
      return "no_credentials"
    }
  }
};

export const Logout = async() => {
    await Parse.User.logOut()
    clearUserData()
};


export const registerUser = async( username, password, temporaryUsername ) => {
  if (username !== null && username !== "" && password !== null && password !== "")
  {
    let _address = {
      flat_number: "",//localStorage.getItem("primary_address_flat_number"),
      street: "",//localStorage.getItem("primary_address_street"),
      postcode: "",//localStorage.getItem("primary_address_postcode"),
      city: "",//localStorage.getItem("primary_address_city"),
      longitude: localStorage.getItem("primary_address_longitude"),
      latitude: localStorage.getItem("primary_address_latitude"),
      building_number: "",//localStorage.getItem("primary_address_building_number")
    }

    const request_params = {
      username: username.toLowerCase(), 
      password: password, 
      whatType: "customer",
      email: username.toLowerCase(),
      temporaryUsername: temporaryUsername?true:false,
      registrationSource: "zio",
      address: _address
    }
    console.log(request_params)
    try 
    {
      const result = await Parse.Cloud.run("registerUser", request_params).catch(error => {
           console.log(error);
       });

      if (result && result.message === "ok")
      {
        return "ok"
      }
      else  
      {
        return "error"
      }
    } catch (error) 
    {
        return "error"
    }
  }
  else  
  {
    return "no_credentials"
  }
}

export const resetUserPassword = async( username ) => {
  if (username !== null && username !== "")
  {
    try 
    {
      const result = await Parse.User.requestPasswordReset(username)
        .then(() => {
          // Password reset request was sent successfully
        }).catch((error) => {
          // Show the error message somewhere
          // alert("Error: " + error.code + " " + error.message);
        });
        console.log(result)
      if (result && result.message === "ok")
      {
        return "ok"
      }
      else  
      {
        return "error"
      }
    } catch (error) 
    {
        return "error"
    }
  }
  else  
  {
    return "no_email"
  }
}

export function isLoggedIn()
{
    let user_data = Parse.User.current()
    if (user_data == null)
    {
      return false
    }
    else 
    {
      updateLocalStorage(user_data)
      return true
    }
};

export function getUserObjectId()
{
    const user_data = Parse.User.current()
    if (user_data == null)
    {
      return null
    }
    else 
    {
      return user_data.id
    }
};

export function getUserLogin()
{
    const user_data = Parse.User.current()
    if (user_data == null)
    {
      return null
    }
    else 
    {
      return user_data.attributes.username
    }
};

export const refreshUserData = async() => {
  console.log("fetch user data")
  if (Parse.User.current())
  {
    Parse.User.current().fetch()
  }
};

export const getUserLocation = async( city, street, building, setAddresses ) => {
    const response = await axios.get(`https://api.geoapify.com/v1/geocode/search?city=${city}&street=${street}&housenumber=${building}&apiKey=e1e3c364b319441fa84b684fbe99d7e0&limit=50&lang=pl&filter=countrycode:pl`);
    var newArray = []
    // console.log(response)
    for (var i = 0; i < response.data.features.length; i++) 
    {
        if (response.data.features[i].properties.city && response.data.features[i].properties.lat && response.data.features[i].properties.lon && response.data.features[i].properties.street && response.data.features[i].properties.housenumber)
        {
          newArray.push({
              id: i,
              city: response.data.features[i].properties.city,
              street: response.data.features[i].properties.street ? response.data.features[i].properties.street : response.data.features[i].properties.suburb,
              building_number: response.data.features[i].properties.housenumber,
              latitude: response.data.features[i].properties.lat,
              longitude: response.data.features[i].properties.lon,
              postcode: response.data.features[i].properties.postcode,
              // flat_number: location.flat_number,
          })
        }
    }
    if (newArray.length === 0)
    {
      alert("Nie udało się znaleźć adresu. Spróbuj ponownie.")
    }
    setAddresses(newArray);
};


export const setUserPrimaryDeliveryAddress = async( ) => {
    const request_params = { 
        address : {
          city: localStorage.getItem("primary_address_city"),
          street: localStorage.getItem("primary_address_street"),
          building_number: localStorage.getItem("primary_address_building_number"),
          flat_number: localStorage.getItem("primary_address_flat_number"),
          notes: localStorage.getItem("primary_address_notes"),
          phone_number: localStorage.getItem("primary_address_phone_number"),
          latitude: localStorage.getItem("primary_address_latitude"),
          longitude: localStorage.getItem("primary_address_longitude"),
          postcode: localStorage.getItem("primary_address_postcode"),
          user_name: localStorage.getItem("user_name")
        }
    }
    await Parse.Cloud.run("setUserPrimaryDeliveryAddress", request_params).catch(error => {
           console.log(error);
       });
    await refreshUserData()
};


export const updateUserBusinessInformation = async( business_information ) => {
    const request_params = { 
        business_information : business_information
    }
    await Parse.Cloud.run("setUserMisc", request_params).catch(error => {
           console.log(error);
       });
    await refreshUserData()
};



export function clearUserData()
{
    localStorage.removeItem("primary_address_city", "")
    localStorage.removeItem("primary_address_street", "")
    localStorage.removeItem("primary_address_building_number", "")
    localStorage.removeItem("primary_address_flat_number", "")
    localStorage.removeItem("primary_address_phone_number", "")
    localStorage.removeItem("primary_address_postcode", "")
    localStorage.removeItem("primary_address_notes", "")
    localStorage.removeItem("primary_address_longitude")
    localStorage.removeItem("primary_address_latitude")
    localStorage.removeItem("zio_loyalty_card_object_id")
    localStorage.removeItem("current_store_data")
    localStorage.removeItem("current_store_object_id")
    localStorage.removeItem("zio_loyalty_card_points")
    localStorage.removeItem("user_name")    
};

export function isUserAddressAdded()
{
  if (!localStorage.getItem("primary_address_city") || localStorage.getItem("primary_address_city") === "" || !localStorage.getItem("primary_address_street") || localStorage.getItem("primary_address_street") === "")
  {
    return false
  }
  else  
  {
    return true
  }
}


export function getUserData()
{
  return Parse.User.current()
}
