import {useEffect} from 'react';
import { useHistory  } from "react-router-dom"
import { isCustomStoreAvailable } from "../services/store"
import { Navbar, NavbarSpace, NavbarBackBtn } from "../components/navbar"
import { Container  } from '@material-ui/core';
import styled from "styled-components"
import Button from '@material-ui/core/Button';
import {color_accent} from "../styles/globalstyle"
import { clearBasket } from "../services/basket"

const TextContainer = styled.div`
    text-align: center;
    margin-top: 80px;
    box-shadow: 0px 0px 2px 0px rgb(0,0,0,.1);
    border-radius:6px;
    padding: 16px;
`

const Title = styled.h1`
    font-size: 60px;
    color:${color_accent};
`

const ButtonContainer = styled.div`
    margin-top: 60px;
    padding-bottom: 40px;
`


export default function PaymentError() {

    const history = useHistory();

    function goTo()
    {
        history.push('/store/'+localStorage.getItem("current_store_object_id"))
    }

    function goToHome()
    {
        history.push('/')
    }

    useEffect(() => {
        document.title = "coś poszło nie tak..."  
        clearBasket()
    }, [])

  return (
    <>{isCustomStoreAvailable()?<>
            <NavbarBackBtn storeData={ {logo_image_url:localStorage.getItem("custom_store_logo_image_url")} }/>
            <NavbarSpace/>
            <Container>
                <TextContainer>

                    <Title>Wystąpił błąd</Title>

                    <h2>Twoja płatność została anulowana</h2>
                    <h2>Wróć do koszyka aby spróbować jeszcze raz</h2>
                    <h2>lub</h2>
                    <h2>Sprawdź list zamówień aby upewnić sie że zamówienie nie zostało złożone.</h2>
        
                    <ButtonContainer>
                        <Button variant="contained" color="primary" onClick={goToHome} >
                            Mój koszyk
                        </Button>
                    </ButtonContainer>
                </TextContainer>

            </Container>
        </>:<>
            <Navbar showBack={true} showIcon={true} showAddressBar={true} showUserOptions={true} />
            <NavbarSpace/>
            <Container>
                <TextContainer>

                    <Title>Wystąpił błąd</Title>

                    <h2>Twoja płatność została anulowana</h2>
                    <h2>Wróć do koszyka aby spróbować jeszcze raz</h2>
                    <h2>lub</h2>
                    <h2>Sprawdź list zamówień aby upewnić sie że zamówienie nie zostało złożone.</h2>
        
                    <ButtonContainer>
                        <Button variant="contained" color="primary" onClick={goTo} >
                            Mój koszyk
                        </Button>
                    </ButtonContainer>
                </TextContainer>

            </Container>
        </>}
    </>
  )
}

