import styled from "styled-components"
import { withStyles } from '@material-ui/core/styles';
import {ProductPrice} from "../components/menu"
import Radio from '@material-ui/core/Radio';

const MyRadio = withStyles({
  root: {
    '&$checked': {
      color: "#8363B7"
    },
  },
  checked: {},
})((props) => <Radio  color="default" {...props} />);

const Grid = styled.div`
	display: grid;
	grid-template-columns: 3fr 1fr;
	height: 42px;
`

const Label = styled.div`
	cursor: pointer;
	margin-right: 8px;

`

const Price = styled(ProductPrice)`
	text-align: right;
	height: 32px;
	padding: 0;
	margin: 0;
	margin-top: 4px;
	margin-right: 24px;
`

export function ProductModifier({modifier, index, modifierSelected, selectModifier})
{
	return <Grid onClick={(e)=>{ selectModifier(modifier, index) }}>
		<Label>
			<MyRadio 
				style = {{marginLeft:"-10px"}}
				id={modifier.id} 
				value={modifier} 
				name="modifier"
				label={modifier.name} 
				onChange={()=>{selectModifier(modifier, index)}}
				checked={modifierSelected === index}
				size="small"
				color="default"
			/>	
			{modifier.name}
		</Label>
		<Price>{modifier.price} zł</Price>
	</Grid>
}