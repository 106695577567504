import styled from "styled-components"
import DirectionsBikeIcon from '@material-ui/icons/DirectionsBike';
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';
import PhoneIcon from '@material-ui/icons/Phone';
import Fade from '@material-ui/core/Fade';
import Chip from '@material-ui/core/Chip';
import Fab from '@material-ui/core/Fab';

const StoreTopContainer = styled.div`
	padding-top:14px;
`
const StoreName = styled.h1`
	font-size: 36px;
	font-weight: 700;
`

export const StoreDescription = styled.div`
	font-size: 14px;
	color: gray;
`

const OpeningTimes = styled.div`
	font-size: ${({is_open}) => (is_open ? `14px` : `24px` ) };
	color: ${({is_open}) => (is_open ? `black` : `red` ) }
`

const InfoContainer = styled.div`
	display: grid;
  	grid-template-columns: 50px auto;
  	height: 64px;
`

const InfoText = styled.div`
	font-size: 14px;
`

export function StoreInfoName({name, opening_times, description, is_open})
{
	return <>
		<Fade in={true} timeout={1000}>
			<StoreTopContainer>
				<OpeningTimes is_open={is_open}>
					{opening_times}
				</OpeningTimes>
				<StoreName>
					{is_open?"":"(zamknięte) "}{name}
				</StoreName>
				{description&&description!==""?<OpeningTimes>
					{description}
				</OpeningTimes>:null}
			</StoreTopContainer>
		</Fade>
	</>
}

export function StoreInfoDelivery({minimum_order_value, delivery_price, free_delivery_from, storeWithinRange, userAddressAdded, notLoggedInCallback, waitingTime, highTraffic})
{
	return <>
		<Fade in={true} timeout={1500}>
			<>
				{userAddressAdded?<> 
					{storeWithinRange===true?<InfoContainer>
						<DirectionsBikeIcon fontSize="large" style={{marginTop:"6px"}} />
						<div>
							<InfoText>Minimalna kwota zamówienia {minimum_order_value}zł</InfoText>
				    		<InfoText>Koszt dostawy {delivery_price}zł</InfoText>
				    		{highTraffic?<InfoText style={{color:"red", fontWeight:"800"}} >Długi czas oczekiwania, powyżej 90 min</InfoText>:<div>
				    			{waitingTime&&waitingTime!==""?<InfoText >Dostawa za: {waitingTime} min</InfoText>:null}
				    		</div>}
				    		{free_delivery_from&&free_delivery_from>0?<Chip variant="outlined" size="small" label= {"Darmowa dostawa od " + free_delivery_from + "zł"} color="secondary" style={{marginTop:"6px"}} />:null}
				    	</div>
				  	</InfoContainer>:<InfoContainer>
						<DirectionsBikeIcon fontSize="large" style={{marginTop:"6px", color:"red"}} />
						<div style={{color:"red"}}>
							<InfoText style={{fontSize: "18px"}}>UWAGA: Jesteś poza zasięgiem dostaw</InfoText>
				    	<Fab onClick={notLoggedInCallback} variant="extended" color="primary" style={{ height:"24px", marginTop:"4px" }} >Podaj inny adres</Fab>
				    	</div>
				  	</InfoContainer>}
				</>:<InfoContainer> 
					<DirectionsBikeIcon fontSize="large" style={{marginTop:"6px"}} />
					<div >
						<InfoText>Sprawdź czy jesteś w zasięgu dostaw</InfoText>
			    		<Fab onClick={notLoggedInCallback} variant="extended" color="primary" style={{ height:"24px", marginTop:"4px" }} >Podaj adres / zaloguj się</Fab>
			    	</div>
				</InfoContainer>}
			</>
						
	  </Fade>
	</>
}

export function StoreInfoCollect({address, waitingTime, highTraffic})
{
	return <>
	<Fade in={true} timeout={1500}>
		<InfoContainer>
		<DirectionsWalkIcon fontSize="large" style={{marginTop:"6px"}} />
		<div>
			<InfoText>Możliwość odbioru osobistego bez kwoty minimalnej</InfoText>
    		<InfoText>Adres: <a href={"https://www.google.com/maps/search/" + address} target="_blank" >{address}</a></InfoText>
			{highTraffic?<InfoText style={{color:"red", fontWeight:"800"}} >Długi czas oczekiwania, powyżej 60 min</InfoText>:<div> 
				{waitingTime&&waitingTime!==""?<InfoText>Odbiór za: {waitingTime} min</InfoText>:null}
			</div>}

	  </div>
    </InfoContainer>
</Fade>
	</>
}

export function StoreInfoContact({phone_number})
{
	return <>
	<Fade in={true} timeout={1500}>
		<InfoContainer>
		<PhoneIcon fontSize="large" style={{marginTop:"6px"}} />
		<div>
			<InfoText>Numer telefonu</InfoText>
		     <a href={"tel:" + phone_number} >+{phone_number}</a>
		  </div>
    </InfoContainer>
</Fade>
	</>
}