import Parse from "parse"
import React, {useState, useEffect} from 'react';
import { Navbar, NavbarSpace, CustomNavbar } from "../components/navbar"
import { OrderRow } from "../components/orders"
import { Container } from '@material-ui/core';
import { LoginDialog } from "../components/login_dialog"
import { useHistory  } from "react-router-dom"


async function fetchData(setActiveOrders, setPastOrders) {
    const Order = Parse.Object.extend("Order");
    const orderQuery = new Parse.Query(Order);
    orderQuery.limit(25)
    orderQuery.lessThan("status", 6)
    orderQuery.include("store,schedule")
    orderQuery.exists("store");
    orderQuery.descending("createdAt")
    if (localStorage.getItem("custom_store_object_id")!==null && localStorage.getItem("custom_store_object_id")!=="")
    {
        orderQuery.equalTo("storeObjectId", localStorage.getItem("custom_store_object_id"))
    }
    const orderResults = await orderQuery.find();
    let _active_orders = []
    let _past_orders = []
    for (let i = 0; i < orderResults.length; i++) 
    {
        // console.log(orderResults[i])
        const _store = orderResults[i].get("store")
        let _completion_time = orderResults[i].get("completionTime")
        const _delivery_information = orderResults[i].get("deliveryInformation")
        if (_delivery_information && _delivery_information.drop_time)
        {
            _completion_time = _delivery_information.drop_time
        }
        let _completion_date_string = ""
        let _completion_time_string = ""
        let _schedule_delivery_note = ""
        let _schedule_collect_note = ""
        if (_completion_time > 0)
        {
            const _date = new Date(_completion_time * 1000)
            _completion_date_string =  _date.toLocaleDateString("pl-PL")
            _completion_time_string = _date.getHours() + ":" + ("0" + _date.getMinutes()).substr(-2)
            const _schedule = orderResults[i].get("schedule")
            if (_schedule)
            {
                if (_schedule.get("tag") === "next_day")
                {
                    _completion_time_string = _completion_date_string
                    _schedule_delivery_note = _schedule.get("deliveryNote")
                    _schedule_collect_note = _schedule.get("collectNote")
                }
                else if (_schedule.get("tag") === "date")
                {
                    _completion_time_string = _completion_date_string
                    _schedule_delivery_note = _schedule.get("deliveryNote")
                    _schedule_collect_note = _schedule.get("collectNote")   
                }
                else if (_schedule.get("tag") === "days")
                {
                    _schedule_delivery_note = _completion_date_string
                }
            }
        }
        else
        {
            if (orderResults[i].get("deliveryType") === "delivery")
            {
                _completion_date_string = "ustalamy czas dostawy"
                _completion_time_string = "ustalamy czas"
            }
            else  
            {
                _completion_date_string = "ustalamy czas odbioru"
                _completion_time_string = "ustalamy czas"
            }
        }



        if (orderResults[i].get("status") < 3)
        {
            _active_orders.push({
                object_id: orderResults[i].id,
                store_name: _store.get("name"),
                order_id: orderResults[i].get("orderId"),
                store_object_id: orderResults[i].get("storeObjectId"),
                store_logo_url: _store.get("logoUrlAdmin").url(),
                products_quantity: orderResults[i].get("products").length,
                order_value: orderResults[i].get("orderValue"),
                delivery_type: orderResults[i].get("deliveryType"),
                status: orderResults[i].get("status"),
                completion_date: _completion_date_string,
                completion_time: _completion_time_string,
                schedule_delivery_note: _schedule_delivery_note,
                schedule_collect_note: _schedule_collect_note
            })
        }
        else
        {
            // console.log(_store)
            _past_orders.push({
                object_id: orderResults[i].id,
                store_name: _store.get("name"),
                store_object_id: orderResults[i].get("storeObjectId"),
                store_logo_url: _store.get("logoUrlAdmin").url(),
                products_quantity: orderResults[i].get("products").length,
                order_value: orderResults[i].get("orderValue"),
                completion_date: "Zakończone: " + _completion_date_string
            })
        }
    }
    setActiveOrders(_active_orders)
    setPastOrders(_past_orders)   
}

export default function Orders() {
    const [defaultZioUi, setDefaultZioUi] =  useState(localStorage.getItem("custom_store_object_id")===null||localStorage.getItem("custom_store_object_id")===""?true:false)
    const [activeOrders, setActiveOrders] = useState([])
    const [pastOrders, setPastOrders] = useState([])
    const history = useHistory()

    let _interval
    let _timeout
    function callbackAfterLogin()
    {
        fetchData(setActiveOrders, setPastOrders)
        _interval = setInterval(()=>{  fetchData(setActiveOrders, setPastOrders) }, 1000*60*5)
        _timeout = setTimeout(()=>{ fetchData(setActiveOrders, setPastOrders) }, 1000*90)
    }

    function callbackAfterCancel()
    {
        history.goBack()
    }

    function goToStore(store_object_id)
    {
        history.push('/store/'+store_object_id)
    }

    useEffect(() => {
        document.title = "moje zamówienia"  

        function visibilitychange(e)
        {
             if (!document.hidden)
             {
                fetchData(setActiveOrders, setPastOrders)
             }
        }
        if (typeof window !== 'undefined' && window.addEventListener) 
        {
            window.removeEventListener('visibilitychange', visibilitychange)
            window.addEventListener('visibilitychange', visibilitychange, false)
        }
        return () => {
            window.removeEventListener('visibilitychange', visibilitychange)
            clearTimeout(_timeout)
            clearTimeout(_interval)
           }
    }, [])

  return (
    <>
        {defaultZioUi?<Navbar showBack={true} showIcon={true} showAddressBar={true} showUserOptions={true} />:<CustomNavbar tabIndex={1} storeData={ {logo_image_url:localStorage.getItem("custom_store_logo_image_url")} }/>}
        <NavbarSpace/>
        <LoginDialog callbackAfterLogin={callbackAfterLogin} callbackIfLoggedin={callbackAfterLogin} callbackAfterCancel={callbackAfterCancel} />
        <Container>
            <h2 style={{color:"green"}}>AKTYWNE</h2>
            {activeOrders.length>0?<OrderRow orders={activeOrders} logoCallback={goToStore} isActive={true}/>:<div>Brak zamówień</div>}
            {pastOrders.length>0?<h2>POPRZEDNIE ZAMÓWIENIA</h2>:null}
            <OrderRow orders={pastOrders} logoCallback={goToStore} isActive={false}/>

        </Container>
    </>
  )
}

