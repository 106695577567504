import styled from "styled-components"
import { useHistory  } from "react-router-dom"
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import Tooltip from '@material-ui/core/Tooltip';
import {color_primary} from "../styles/globalstyle"
import { Container  } from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Fab from '@material-ui/core/Fab';
import Chip from '@material-ui/core/Chip';


const NavbarStyled = styled.div`
	background-color: ${({transparent}) => (transparent ? `transparent` : `rgba(250, 250, 250, 255)` ) };
	padding: 10px;
	position: fixed;
	width: 100%;
	height: 50px;
	z-index: 999;
	box-shadow: ${({transparent}) => (transparent ? `0px 0px 0px 0px` : `0px 0px 4px 0px grey` ) } ;	
`

const TopPadding = styled.div`
	height: 70px;
`

const Search = styled.div`
	border-radius: 8px;
	margin-top: 4px;
	margin-left: 32px;
	height: 44px;
	max-width: 320px;
	min-width: 120px;
	cursor: pointer;
	background-color: white; 
	float: left;
`

const Address = styled.div`
	color: ${color_primary};
	margin-top: 4px;
	margin-left: 32px;
	height: 44px;
	max-width: 320px;
	cursor: pointer;
	float: left;
`

const AddressLabel = styled.div`
	text-align: center;
	font-size: 15px;
	margin-top:11px;
	padding-left: 16px;
	padding-right: 4px;
	float: left;
`

const AddressIcon = styled.div`
	padding: 0px;
	margin-top:9px;
	padding-left: 4px;
	padding-right: 8px;
	float: right;
`

const Options = styled.div`
	color: white;
	margin-top: 4px;
	margin-right: 16px;
	height: 44px;
	width: 100px;
	cursor: pointer;
	float: right;
`

const UserIcon = styled.div`
	margin-top: 4px;
	margin-right:8px;
	float: right;
	color: ${color_primary};
`

const OrdersIcon = styled.div`
	margin-top: 4px;
	margin-left:10px;
	float: left;
	color: ${color_primary};
`

const Basket = styled.div`
	color: white;
	border-radius: 8px;
	margin-top: 4px;
	margin-right: 24px;
	height: 44px;
	min-width: 80px;
	cursor: pointer;
	background-color: ${color_primary}; 
	float: right;
`

const BasketLabel = styled.div`
	text-align: center;
	font-size: 14px;
	margin-top:12px;
	padding-left: 4px;
	padding-right: 12px;
	float: left;
`

const BasketIcon = styled.div`
	padding: 0px;
	margin-top:9px;
	padding-left: 12px;
	padding-right: 4px;
	float: left;
`

const BackButton = styled.div`
	margin-top: 9px;
	margin-right: 16px;
`

const Item = styled.div`
	float: left;
`


export function BackButtonDefinition() {
	return <BackButton> <ArrowBackIcon/> </BackButton>
}
export function Navbar({showBack, basket, GoToCheckout, showAddressBar, showUserOptions, showSearch, searchResults, onSearchChange, transparent, storeIsOpen}){
	const history = useHistory();
	function SetAddress()
	{
		history.push('/myaccount')
	}

	function GoHome()
	{
		history.push('/start')
	}

	function GoToMyOrders()
	{
		history.push('/orders')
	}

	function GoToMyAccount()
	{
		history.push('/myaccount')
	}

	function GoToStore(store_object_id)
	{
		console.log(store_object_id)
		history.push('/store/'+store_object_id)
	}

	let address = "podaj adres"
	const lat = localStorage.getItem("primary_address_latitude")
    const lon = localStorage.getItem("primary_address_longitude")
    if (lat != null && lon != null )  
    {
		if (localStorage.getItem("primary_address_city" ))
		{
			address = localStorage.getItem("primary_address_city" )
		}
		if (localStorage.getItem("primary_address_street" ))
		{
    		address = address + ", " + localStorage.getItem("primary_address_street" )
		}
		if (localStorage.getItem("primary_address_building_number" ))
		{
    		address = address + " " + localStorage.getItem("primary_address_building_number" )
		}
    }
    else  
    {
    	showBack = false
    }

    if (basket != null)
    {
	    var subtotal = basket.reduce((total, order) => {
	    	if (order.selected_options)
	    	{
				for (var i = 0; i < order.selected_options.length; i++) 
		    	{
		    		total = total + order.selected_options[i].price
		    	}
	    	}
			return Math.round( (total + (order.price*order.quantity) ) * 100 ) / 100
		}, 0)
    }


	return <NavbarStyled transparent={transparent}>
		<Container maxWidth="lg">
			<Item onClick={ history.goBack} style={{ marginTop: "6px" }}>
				{showBack?<BackButtonDefinition  />:<div/>}
			</Item>
			<Item>
				<img
			      src='../icon64.png'
			      alt="ZiO food icon"
			      style={{ width: 44, height: 44, marginTop: "4px" }}
			      onClick={GoHome}
			    />
	    	</Item>
		    {showAddressBar?
		    	<Tooltip title="adres dostawy">
			    	<Address onClick = {SetAddress}>
				    	<AddressLabel>
				    		{address?address:"podaj adres"}
				    	</AddressLabel>
				    	<AddressIcon>
				    		<LocationOnIcon/>
				    	</AddressIcon>
				    </Address>
				</Tooltip>:null}

			{showSearch?<Search>
            	<InputBase
                	style={{marginLeft:"10px", padding:"6px"}}
                	placeholder="szukaj lokalu"
                	inputProps={{ 'aria-label': 'szukaj lokalu' }}
                	onChange={ (e)=> { e.preventDefault(); onSearchChange(e.target.value) } }
                	onBlur={ (e)=> { e.preventDefault(); setTimeout(function(){ e.target.value = ""; onSearchChange(false) }, 500) } }
              	/>
              	<IconButton type="submit" style={{padding: "10px", float: "right"}} aria-label="search">
                	<SearchIcon  />
              	</IconButton>
           		{searchResults.length>0?<Paper><List style={{backgroundColor:"white", maxHeight:"640px", overflow: 'auto'}}>
           			{searchResults.map(store => (
	           			<>
							<ListItem alignItems="flex-start" onClick={(e)=>{ e.preventDefault(); GoToStore(store.id) } }>
						        <ListItemAvatar>
						          <Avatar alt={store.name} src={store.logo_image_url} />
						        </ListItemAvatar>
						        <ListItemText
						          primary={store.name}
						          secondary={store.label}
						        />
					      </ListItem>
					      <Divider variant="inset" component="li" />
				      </>
					))}
			      
			    </List></Paper>:null}
            </Search>:null}
	
	    	{subtotal > 0 ?<Container maxWidth="lg">
		    <Fab onClick={GoToCheckout} variant="extended" color="primary" style={{zIndex: "99", position: "fixed", bottom: "32px", right: "calc(50% - 110px)", width: "220px"}} >
	          <ShoppingBasketIcon style={{ padding: "10px" }} />
	           KOSZYK • {subtotal} zł
	        </Fab>
		</Container>:null}
		{storeIsOpen===false?<Item>
	    		<Chip label="Teraz jesteśmy zamknięci, wróć później." style={{backgroundColor:"red", color:"white", fontSize:"18px", height: "50px", borderRadius:"25px", zIndex: "100", position: "fixed", bottom: "32px", right: "calc(50% - 180px)", width: "360px" }} />
	    	</Item>:null}

	    	
	    	{showUserOptions?<Options>
	    		<Tooltip title="moje zamówienia">
		    		<OrdersIcon onClick={GoToMyOrders}>
		    			<ShoppingBasketIcon fontSize="large"/>
		    		</OrdersIcon>	
	    		</Tooltip>	
	    		<Tooltip title="moje konto">
		    		<UserIcon onClick={GoToMyAccount}>
		    			<PersonOutlineIcon fontSize="large"/>
		    		</UserIcon>		
	    		</Tooltip>
	    	</Options>:null}
		</Container>
	</NavbarStyled>
}

export function CustomNavbar({showBack, basket, GoToCheckout, storeData, tabIndex, storeIsOpen }){
	const history = useHistory();
	function SetAddress()
	{
		history.push('/start')
	}

	function GoHome()
	{
		history.push('/')
	}

	function GoToMyOrders()
	{
		history.push('/orders')
	}

	function GoToMyAccount()
	{
		history.push('/myaccount')
	}

	function GoToMenu()
	{
		history.push('/')
	}

	function GoToLocations()
	{
		history.push('/locations')
	}

	let address = "podaj adres"
	const lat = localStorage.getItem("primary_address_latitude")
    const lon = localStorage.getItem("primary_address_longitude")
    if (lat != null && lon != null )  
    {
		if (localStorage.getItem("primary_address_city" ))
		{
			address = localStorage.getItem("primary_address_city" )
		}
		if (localStorage.getItem("primary_address_street" ))
		{
    		address = address + ", " + localStorage.getItem("primary_address_street" )
		}
		if (localStorage.getItem("primary_address_building_number" ))
		{
    		address = address + " " + localStorage.getItem("primary_address_building_number" )
		}
    }
    else  
    {
    	showBack = false
    }

    if (basket != null)
    {
	    var subtotal = basket.reduce((total, order) => {
	    	if (order.selected_options)
	    	{
				for (var i = 0; i < order.selected_options.length; i++) 
		    	{
		    		total = total + order.selected_options[i].price
		    	}
	    	}
			return Math.round( (total + (order.price*order.quantity) ) * 100 ) / 100
		}, 0)
    }


	return <>
		<Paper square style={{zIndex: "99", position: "fixed", width:"100%", height: "70px"}}>
			<Container maxWidth="lg">
				{storeData.logo_image_url?<Item>
					<img
				      src={storeData.logo_image_url}
				      alt="app icon"
				      style={{ width: 70, height: 70 }}
				      onClick={GoHome}
				    />
		    	</Item>:null}

		    	<Tabs
			        value={tabIndex}
			        onChange={(e, index)=>{ 
				        	if (index == 0) { 
				        		GoToMenu() 
				        	} 
				        	else if (index == 1) { 
				        		GoToMyOrders() 
				        	} 
				        	else if (index == 2) { 
				        		GoToMyAccount() 
				        	} 
				        	else if (index == 3) { 
				        		GoToLocations() 
				        	} 
				        }
				    }
			        variant="fullWidth"
			        indicatorColor="secondary"
			        textColor="secondary"
			        aria-label="icon label tabs example"
			      >
			        <Tab icon={<RestaurantMenuIcon />} label="Menu" goTo = "home" />
			        <Tab icon={<ShoppingBasketIcon />} label="zamówienia" />
			        <Tab icon={<PersonOutlineIcon />} label="konto" />
			        {window.localStorage.getItem("custom_store_user_object_id")?<Tab icon={<LocationOnIcon />} label="lokale" goTo = "locations" />:null}
			      </Tabs>		    	 
			</Container>
	    </Paper>
	    {tabIndex===0 && subtotal > 0 ?<Container maxWidth="lg">
		    <Fab onClick={GoToCheckout} variant="extended" color="primary" style={{zIndex: "99", position: "fixed", bottom: "32px", right: "calc(50% - 128px)", width: "256px"}} >
	          <ShoppingBasketIcon style={{ padding: "10px" }} />
	           KOSZYK • {subtotal} zł
	        </Fab>
		</Container>:null}
		{storeIsOpen===false?<Item>
	    		<Chip label="Teraz jesteśmy zamknięci, wróć później." style={{backgroundColor:"red", color:"white", fontSize:"18px", height: "50px", borderRadius:"25px", zIndex: "100", position: "fixed", bottom: "32px", right: "calc(50% - 180px)", width: "360px" }} />
	    	</Item>:null}
	</>
}

export function NavbarBackBtn({storeData}){
	const history = useHistory();
	function GoHome()
	{
		history.push('/')
	}
	return <>
		<Container maxWidth="lg">
			<Item onClick={ history.goBack} style={{ marginTop: "6px" }}>
				<BackButtonDefinition  />
			</Item>
			{storeData.logo_image_url?<Item>
				<img
			      src={storeData.logo_image_url}
			      alt="app icon"
			      style={{ width: 66, height: 66 }}
			      onClick={ GoHome }
			    />
	    	</Item>:null}
		</Container>
	</>
}

export function NavbarSpace()
{
	return <TopPadding>
	</TopPadding>
}