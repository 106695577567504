import styled from "styled-components"

const Container = styled.div`
	width: 200px;
	border-radius: 4px;
	box-shadow: 0px 0px 1px 0px grey;
	padding: 2px;
	margin: auto;
`

const Text = styled.div`
 	float: left;
 	margin-top: 7px;
 	margin-right: 16px;
 	margin-left: 16px;
`

export function PoweredByZio()
{
	return <Container>		
		<a href="https://www.web.ziofood.pl" target="_blank">
		<Text>
			Wspierane przez
		</Text>
		<div>
			<img
		      src='../icon_zio.png'
		      alt="ZiO app icon"
		      style={{ width: 28, height: 28, marginTop: "4px" }}
		    />
		</div>
		</a>
	</Container>
}