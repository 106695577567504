import Parse from "parse"
import React, { useState, useEffect } from 'react'
import {color_primary} from "../styles/globalstyle"
import styled from "styled-components"
import axios from "axios";
import { useHistory  } from "react-router-dom"
import { StartBanner } from "../components/banner"
import { checkDeviceType } from "../services/helper"
import FastfoodIcon from '@material-ui/icons/Fastfood';
import RoomServiceIcon from '@material-ui/icons/RoomService';
import CakeIcon from '@material-ui/icons/Cake';
import StarIcon from '@material-ui/icons/Star';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import PublicIcon from '@material-ui/icons/Public';
import LocalDiningIcon from '@material-ui/icons/LocalDining';
import LocalDrinkIcon from '@material-ui/icons/LocalDrink';
import LocalGroceryStoreIcon from '@material-ui/icons/LocalGroceryStore';

import Button from '@material-ui/core/Button';
import TagManager from 'react-gtm-module'

import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "24px",
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 580,
    left: 50
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
}));


const Container = styled.div`
  text-align: center;
  height: 880px;
`

const CirclesContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, auto));
    place-items: center;
`

const Circle = styled.div`
  border-radius: 50%;
  width: 150px;
  height: 150px;
  ${({isActive}) => (isActive ? `background-color: ${color_primary}` : `background-color: gray` )};
  &:hover {
    cursor: pointer;
    box-shadow: 0px 0px 12px 0px gray;
  }
`

const CitiesContainer = styled.div`
  margin: auto;
  width: 50%;
  margin-top: 64px;
`

const CitiesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(128px, auto));
  gap: 16px;

`

const CityItem = styled.div`
  width: 100%;
  height: 44px;
  border-radius:8px;
  box-shadow: 0px 0px 0px 0px grey;
  transition: .3s;
  &:hover {
    cursor: pointer;
    box-shadow: 0px 0px 8px 0px grey;
  }
  background-color: #8363B7;
`

const CityLabel = styled.h3`
    font-size: 18px;
    margin-top: 10px;
    font-weight: 600;
    color: white;
`

const tagManagerArgs = {
    dataLayer: {
      event: 'pageview',
      pagePath: '/start',
      pageTitle: 'Start',
    },
    dataLayerName: 'StartPageDataLayer'
}

async function fetchData(setConfig,) 
{
  const _config_timestamp = Number(window.localStorage.getItem("config_timestamp"))
  const current_time = Date.now();
  if (_config_timestamp && (_config_timestamp + 60*60) > current_time/1000)
  {
    const result = Parse.Config.current()
    setConfig(result.get("sections"))
  }
  else  
  {
    const result = await Parse.Config.get()
    setConfig(result.get("sections"))
    const current_time = Date.now();
    window.localStorage.setItem("config_timestamp", current_time/1000) // lat that app was fully new loaded
  }
}

export default function Start({ Component, pageProps }) 
{
  const [config, setConfig] = useState({
    restaurant:true,
    catering:false,
    cake:false,
    vegetable:false,
    foodtruck:false,
    mtp:false,
    breakfast:false,
    special:false,
    drink: false,
    delicatese: false,
    special_label:"Dzień Matki"
  })
  const history = useHistory();
  TagManager.dataLayer(tagManagerArgs)

  let location = {
    city : "",
    street : "",
    building_number: "",
    flat_number: "",
    address: ""
  }

  function goTo(go_to, is_active)
  {
    if (is_active)
    {
      history.push('/'+go_to)
    }
    else  
    {

    }
  }

  useEffect(() => {
    checkDeviceType(history)
    if (localStorage.getItem("primary_address_city") === null || localStorage.getItem("primary_address_city") === "" )
    {
      localStorage.setItem("primary_address_latitude", 52.4058095 )
      localStorage.setItem("primary_address_longitude", 16.8924353 )
      localStorage.setItem("primary_address_city", "Poznań" )
    }
    fetchData(setConfig) 
  }, [])

  const inputStyle = useStyles();

  return (
 
        <Container style={{width: "100%"}}>
          <StartBanner/>
          <Button variant="contained" color="primary" style={{float: "right", margin: "16px"}} href="https://www.web.ziofood.pl" target="blanck" >
            Więcej o nas
          </Button>
          <div style={{ paddingTop:"1px"}}>
          <h1 style={{color: "white", paddingTop:"160px", fontSize:"48px", textShadow: "1px 1px #00000080"}}>
            Cześć, witamy w ZiO food.
          </h1>
          <p style={{color: "white", fontSize:"19px", textShadow: "1px 1px #00000080"}}>twoim miejscu do zamawiania jedzenia online.</p>
          <div style = {{ maxWidth: "640px", margin: "auto" }}>
            <CirclesContainer>
              {config.special?<Circle onClick={()=>{goTo("okazjonalne", config.special)}} style={{marginTop: "24px"}} isActive={config.special}>
                <StarIcon style={{fontSize:"60px", marginTop: "24px", color: "white"}} />
                <p style={{marginTop: "0px", color: "white"}}>{config.special_label}</p>
              </Circle>:null}

              {config.mtp?<Circle onClick={()=>{goTo("mtp", config.mtp)}} style={{marginTop: "24px"}} isActive={config.mtp}>
                <PublicIcon style={{fontSize:"60px", marginTop: "24px", color: "white"}} />
                <p style={{marginTop: "0px", color: "white"}}>{config.mtp_label}</p>
              </Circle>:null} 

              <Circle onClick={()=>{goTo("restauracje", config.restaurant)}} style={{marginTop: "24px"}} isActive={config.restaurant} >
                <FastfoodIcon style={{fontSize:"60px", marginTop: "24px", color: "white"}} />
                <p style={{marginTop: "0px", color: "white"}} >Restauracje</p>
              </Circle>
              
              <Tooltip title={config.breakfast?"":"dostępne wkrótce"} placement="top">
                <Circle onClick={()=>{goTo("śniadania", config.breakfast)}} style={{marginTop: "24px"}} isActive={config.breakfast}>
                  <LocalDiningIcon style={{fontSize:"60px", marginTop: "24px", color: "white"}} />
                  <p style={{marginTop: "0px", color: "white"}}>Śniadania</p>
                </Circle>
              </Tooltip>

              <Tooltip title={config.cake?"":"dostępne wkrótce"} placement="top">
                 <Circle onClick={()=>{goTo("torty", config.cake)}} style={{marginTop: "24px"}} isActive={config.cake}>
                  <CakeIcon style={{fontSize:"60px", marginTop: "24px", color: "white"}} />
                  <p style={{marginTop: "0px", color: "white"}}>Torty i ciasta</p>
                </Circle>    
              </Tooltip>
              
              <Tooltip title={config.catering?"":"dostępne wkrótce"} placement="top">
                <Circle onClick={()=>{goTo("catering", config.catering)}} style={{marginTop: "24px"}} isActive={config.catering}>
                  <RoomServiceIcon style={{fontSize:"60px", marginTop: "24px", color: "white"}} />
                  <p style={{marginTop: "0px", color: "white"}}>Cateringi</p>
                </Circle>
              </Tooltip>

              <Tooltip title={config.delicatese?"":"dostępne wkrótce"}>
                <Circle onClick={()=>{goTo("delikatesy", config.delicatese)}} style={{marginTop: "24px"}} isActive={config.delicatese}>
                  <LocalGroceryStoreIcon style={{fontSize:"60px", marginTop: "24px", color: "white"}} />
                  <p style={{marginTop: "0px", color: "white"}}>Delikatesy</p>
                </Circle>
              </Tooltip>

              <Tooltip title={config.foodtruck?"":"dostępne wkrótce"}>
                <Circle onClick={()=>{goTo("foodtruck", config.foodtruck)}} style={{marginTop: "24px"}} isActive={config.foodtruck}>
                  <LocalShippingIcon style={{fontSize:"60px", marginTop: "24px", color: "white"}} />
                  <p style={{marginTop: "0px", color: "white"}}>Food Truck</p>
                </Circle>  
              </Tooltip>

              <Tooltip title={config.drink?"":"dostępne wkrótce"}>
                <Circle onClick={()=>{goTo("napoje", config.drink)}} style={{marginTop: "24px"}} isActive={config.drink}>
                  <LocalDrinkIcon style={{fontSize:"60px", marginTop: "24px", color: "white"}} />
                  <p style={{marginTop: "0px", color: "white"}}>Napoje</p>
                </Circle> 
              </Tooltip>

            </CirclesContainer>
            </div>
          </div>        
        </Container>
  
  )
}