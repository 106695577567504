import {Store, StoreGrid, StoreName, StoreExtraInfo, StoreImage} from "../components/stores_grid"
import DirectionsBikeIcon from '@material-ui/icons/DirectionsBike';
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import Fade from '@material-ui/core/Fade';
import Chip from '@material-ui/core/Chip';

const iconStyle = {
  fontSize: 14,
  marginRight: 4,
  marginTop: -4
};

const iconStyleGreen = {
  fontSize: 14,
  marginRight: 4,
  marginTop: -4,
  color: "green"
};

export function Stores(props)
{
	return <>
			<Fade in={true} timeout={1500}>
				<StoreGrid key={props.key_name} >
					{props.data.map(store => (
							<Store key={store.id}  onClick = {() => { props.goToStore(store.id) } } >
								<StoreImage image={store.image_small_url} is_open={store.is_open}>
									{store.promotion_label?<Chip size="small" label= {store.promotion_label} color="secondary" style={{color:"white", fontWeight:"200", fontSize:"11px"}} />:null}
								</StoreImage>

								<StoreName>
									{store.is_open?"":"(zamknięte) "}{store.name} 
								</StoreName>
								<StoreExtraInfo>
									<DirectionsBikeIcon style={iconStyle} />{store.delivery_price} zł
									{" "}
									<DirectionsWalkIcon style={iconStyle} />{store.distance}km
									{" "}
									{store.zero_waste?<AutorenewIcon style={iconStyleGreen} />:null}

								</StoreExtraInfo>
							</Store>
					))

					}
				</StoreGrid>
			</Fade>
	</>
}