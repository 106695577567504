const cos = Math.cos
const sin = Math.sin
const pi = Math.PI
const sqrt = Math.sqrt
const min = Math.min
const asin = Math.asin
const abs = Math.abs
const round = Math.round

export function getDistance(from, to)
{
  const radius = 63670
  const radian = pi / 180
  const deltaLatitude = sin(radian * (from.latitude - to.latitude) /2)
  const deltaLongitude = sin(radian * (from.longitude - to.longitude) / 2)

  const circleDistance = 2 * asin(min(1, sqrt(deltaLatitude * deltaLatitude + cos(radian * from.latitude) * cos(radian * to.latitude) * deltaLongitude * deltaLongitude)))
  return round( abs(radius * circleDistance) ) / 10
};

export function isMobile()
{
  if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
  {
    // true for mobile device
    return true
  }
  else  
  {
    return false
  }
}

export function checkDeviceType(history)
{
  if ( isMobile() )
  {
    // true for mobile device
    history.push('/mobile')
  }
}
