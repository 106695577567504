import Parse from "parse"
import { useState, useEffect } from 'react';
import { useHistory  } from "react-router-dom"
import { getCurrentStoreData } from "../services/store"
import { StoreRegulationDialog } from "../components/dialogs"

async function getStoreRegulation(setRegulationContent)
{
    const store_data = getCurrentStoreData()
    if (!store_data.regulation_object_id)
    {
        store_data.regulation_object_id = "mivsXKYXEG"
    }
    const CustomData = Parse.Object.extend("CustomData");
    const query = new Parse.Query(CustomData);
    const result = await query.get(store_data.regulation_object_id)//("OGTrJWM0gG") 
    const _content = result.get("data_array")


    const _business_name = store_data.address_information.business_information
    const _store_full_name = store_data.name
    const _email = store_data.address_information.email
    const _distance = store_data.delivery_information.distance.toString()

    for (var i = 0; i < _content.length; i++) 
    {
        _content[i].text = _content[i].text.replace('_business_information_', _business_name)
        _content[i].text = _content[i].text.replace('_store_information_', _store_full_name)
        _content[i].text = _content[i].text.replace('_email_', _email)
        _content[i].text = _content[i].text.replace('_distance_', _distance)
    }

    setRegulationContent(_content)   
}

export default function Storeregulation() 
{
    const history = useHistory();  
    const [openStoreRegulation, setOpenStoreRegulation] = useState(true)
    const [regulationContent, setRegulationContent] = useState([])

    function GoBack()
    {
        history.goBack()
    }

     useEffect(() => {
        getStoreRegulation(setRegulationContent)
      }, [])

    return (
        <>
            <StoreRegulationDialog open={ openStoreRegulation } setOpen={setOpenStoreRegulation} content={regulationContent} callback={GoBack}  />
        </>
    )
}