import styled from "styled-components"

const Container = styled.div`
	padding-left: 50px;
	padding-top: 12px;
	padding-bottom: 12px;
	&:hover {
		cursor: pointer;
	}
`

const Title = styled.div`
	font-size: 16px;
`
const Info = styled.div`
	font-size: 13px;
`

function getPromoString(deliveryType, discountType, minimumOrderValue, value )
{
	let _str = ""

	if (deliveryType === "delivery")
	{
		_str = _str + "tylko w dostawie\n"
	}
	else if (deliveryType === "collect")
	{
		_str = _str + "tylko przy odbiorze osobistym\n"
	} 

	if (discountType === "percent")
	{
		_str = _str + "wartość zniżki: " + (value*100).toString() + "%"

	} 
	else
	{
		_str = _str + "wartość zniżki: " + value.toString() + " zł"
	}
	return _str
}

export function PromocodeRow({code, name, storeObjectId, description, minimumOrderValue, value, expirationDate, discountType, deliveryType, history })
{
	function goTo()
	{
		history.push({pathname: '/store/'+storeObjectId})
	}
	return <Container onClick={goTo}>
	
			<Title>
				{code.toUpperCase()} | {name}
			</Title>
			<Info>
				{description}
			</Info>
			<Info>
				minimalna kwota zamówienia: {minimumOrderValue}zł
			</Info>
			<Info>
				{getPromoString(deliveryType, discountType, minimumOrderValue, value )}
			</Info>
			<Info>
				ważne do: {new Date(expirationDate*1000).toLocaleDateString("pl-PL")}
			</Info>
	</Container>
}
