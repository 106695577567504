function getPromoProduct(products, promotion_tag)
{
	for (let i = 0; i < products.length; i++) 
	{
		if (products[i].tag === "promotion")
		{
			if (promotion_tag && products[i].promotion_tag === promotion_tag)
			{
				return products[i]
			}
		}
	}
	return false
}


export function getMultiItemDiscount(basket_products, delivery_type)
{
	const _promo_product = getPromoProduct(basket_products, "multi_item_discount")
	let _discount = 0
	let _promotion_name = ""
	if (_promo_product)
	{
		if (_promo_product.rule && _promo_product.rule.delivery_type && delivery_type && (_promo_product.rule.delivery_type === "all" || _promo_product.rule.delivery_type === delivery_type) )
		{
			let _items_in_promotion = []
			for (let i = 0; i < basket_products.length; i++)
			{
				if (_promo_product.rule.category_object_id != null) // if category is defined, apply only within this category
				{
	                if (basket_products[i].category_object_id === _promo_product.rule.category_object_id)
	                {
	                	for (let j = 0; j < basket_products[i].quantity; j++)
	                	{
	                		_items_in_promotion.push({
	                			price: basket_products[i].price,
	                			object_id: basket_products[i].objectId
	                		})
	                	}
	                } 
				}  
	            else
	            {
	            	for (let j = 0; j < basket_products[i].quantity; j++)
	            	{
	            		_items_in_promotion.push({
	            			price: basket_products[i].price,
	            			object_id: basket_products[i].objectId
	            		})
	            	}
	            }
			}
	            
			if (_items_in_promotion.length >= _promo_product.rule.minimum_item_count)
			{
				_items_in_promotion.sort( function(a,b) { return a.price - b.price } )
				if (_promo_product.rule.discount_type && _promo_product.rule.discount_value)
				{
					if (_promo_product.rule.discount_type === "percent")
					{
						_discount = _items_in_promotion[0].price * _promo_product.rule.discount_value
					} 
					else if (_promo_product.rule.discount_type === "value")
					{
						_discount = _promo_product.rule.discount_value
					} 
				}
				
				if (_discount > 0)
				{
					_promotion_name = _promo_product.name
				}
			} 
			
		}
		if (_discount > 0)
		{
			_promo_product.show_item = true
		}
		else
		{
			_promo_product.show_item = false
		}
	}
	return {discount:_discount, name:_promotion_name}
}