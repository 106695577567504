// Custom
import { getCustomStoreConfig } from "../services/store"

const config = getCustomStoreConfig()

export const color_primary = config.color_primary?config.color_primary:"#8363B7"
export const color_accent = config.color_accent?config.color_accent:"#FFAB1A"

export const product_item_radius = "0px"
export const main_font = "'Open Sans', sans-serif;"

export const deployment_type = "custom"

// ZiO

// export const color_primary = "#8363B7"
// export const color_accent = "#FFAB1A"

// export const product_item_radius = "4px"
// export const main_font = "'Quicksand', sans-serif;"

// export const deployment_type = "zio"