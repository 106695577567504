import Parse from "parse"
import {useState, useEffect} from 'react';
import { useHistory  } from "react-router-dom"
import { Navbar, NavbarSpace, CustomNavbar } from "../components/navbar"
import { color_primary, color_accent } from "../styles/globalstyle"
import { Container  } from '@material-ui/core';
import styled from "styled-components"
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import { YesNoDialog, AddressSelectDialog } from "../components/dialogs"
import { LoginDialog } from "../components/login_dialog"
import { PromocodeRow } from "../components/promocodes"
import { isCustomStoreAvailable, getCurrentStoreDataKey } from "../services/store"

import { CheckoutAddressRow, CheckoutInfoRow, CheckoutButtonRow, CheckoutLegalInformationRow } from "../components/checkout"
import Switch from '@material-ui/core/Switch';

import { Loader } from "../components/loader"
import { getUserLogin, clearUserData, getUserLocation, setUserPrimaryDeliveryAddress, isLoggedIn, getUserObjectId, isUserAddressAdded, getUserData } from "../services/user"

import { getZioLoyaltyCardPoints } from "../services/loyalty_card"

const Title = styled.h1`
    font-size: 40px;
    color:${color_primary};
`

async function logout(setShowLoader, history)
{
    setShowLoader(true)
    await Parse.User.logOut()
    clearUserData()
    setShowLoader(false)
    history.goBack()
}

async function getPromoCodes(setPromocodes)
{
    var timestamp = Date.now()
    const PromoCodeRedeemed = Parse.Object.extend("PromoCodeRedeemed");
    const queryPromoCodeRedeemed = new Parse.Query(PromoCodeRedeemed);
    queryPromoCodeRedeemed.include("parent")
    queryPromoCodeRedeemed.limit(50)
    queryPromoCodeRedeemed.ascending("expirationDate")
    queryPromoCodeRedeemed.greaterThan("expirationDate", timestamp/1000);
    queryPromoCodeRedeemed.greaterThan("stock", 0);
    if (localStorage.getItem("custom_store_object_id")!==null && localStorage.getItem("custom_store_object_id")!=="")
    {
        queryPromoCodeRedeemed.equalTo("target", localStorage.getItem("custom_store_object_id"))
    }
    const result = await queryPromoCodeRedeemed.find()
    setPromocodes(result)
    // console.log(result)
    // console.log(timestamp/1000)
    // console.log(timestamp)
}

async function redeemPromocode(promocode, setPromocodes, setPromocodeWarrningMessage, setPromocodeSuccessMessage)
{
    let result = await Parse.Cloud.run("redeemPromoCode", {
        code: promocode.toLowerCase(),
        device_id: getUserObjectId()
    }).catch(error => {
           console.log(error);
           setPromocodeWarrningMessage("kod się przedawnił lub jest błędny")
       });

    if (result)
    {
        getPromoCodes(setPromocodes)
        setPromocodeSuccessMessage("kod promocyjny dodany!")
    }
}

async function getZioLoyaltyCardPointsFunction(setZioLoyaltyCardPoints)
{
    const point = await getZioLoyaltyCardPoints()
    setZioLoyaltyCardPoints(point.toString())
}


export default function MyAccount() {
    const [isCustomStore, setIsCustomStore] =  useState(isCustomStoreAvailable())
    const history = useHistory()

    const [login, setLogin] = useState(getUserLogin()?getUserLogin():"")
    const [agreement, setAgreement] = useState(true)
    const [temporaryUser, setTemporaryUser] = useState(true)

    const [openDialog, setOpenDialog] = useState(false)
    const [openAddressDialog, setOpenAddressDialog] = useState(false)
    
    const [showLoader, setShowLoader] = useState(false)
    const [address, setAddress] = useState("")

    // delivery address

    const [addressCity, setAddressCity] = useState("")
    const [addressStreet, setAddressStreet] = useState("")
    const [addressBuilding, setAddressBuilding] = useState("")
    const [addressFlat, setAddressFlat] = useState("")
    const [addresses, setAddresses] = useState([])

    const [leganInformation, setLeganInformation] = useState()

    const [promocodes, setPromocodes] = useState([])
    const [promocodeWarrningMessage, setPromocodeWarrningMessage] = useState("")
    const [promocodeSuccessMessage, setPromocodeSuccessMessage] = useState("")

    const [newPromoCode, setNewPromoCode] = useState("")
    const [zioLoyaltyCardPoints, setZioLoyaltyCardPoints] = useState(0)

    const [showAddress, setShowAddress] = useState(true)
    const [showPromoCodes, setShowPromoCodes] = useState(true)



    function onAgreementClicked()
    {
        setOpenDialog(true)
    }

    function onAgreementYes()
    {
        logout(setShowLoader, history)
    }


    function setUserData()
    {
        setLogin(getUserLogin()?getUserLogin():"")
        const _user_data = getUserData()
        setTemporaryUser(_user_data.get("temporaryUser"))
        const _user_address = _user_data.get("address")
        if (_user_address)
        {
            setAddressCity(_user_address.city)
            setAddressStreet(_user_address.street)
            setAddressBuilding(_user_address.building_number)
            setAddressFlat(_user_address.flat_number)
            setAddress(_user_address.city + ", " + _user_address.street + " " + _user_address.building_number + " / " + _user_address.flat_number)
        }
        getPromoCodes(setPromocodes)

        const _user_misc = _user_data.get("misc")
        if (_user_misc && _user_misc.business_information)
        {
            setLeganInformation(_user_misc.business_information)
        }

        const _user_what_type = _user_data.get("whatType")
      
        if (_user_what_type)
        {
            if (_user_what_type === "corporate_customer")
            {
                setShowAddress(false)
                setShowPromoCodes(false)
            }
        }

        if (!isCustomStore)
        {
            getZioLoyaltyCardPointsFunction(setZioLoyaltyCardPoints)
        }
    }
    
    function callbackAfterLogin()
    {
        if (!isUserAddressAdded() && getCurrentStoreDataKey("delivery_type").delivery)
        {
            setUserData()
            setOpenAddressDialog(true)
        }
        else  
        {
            history.goBack()
        }   
    }

    function updatePromocode(event)
    {
        event.preventDefault();
        setNewPromoCode(event.target.value)
    }

    function onAddPromocodeClose()
    {
        redeemPromocode(newPromoCode, setPromocodes, setPromocodeWarrningMessage, setPromocodeSuccessMessage)
    }

    function updateDeliveryAddress(event)
    {
        event.preventDefault();
        if (event.target.id === "city")
        {
            setAddressCity(event.target.value)
        }
        else if (event.target.id === "street")
        {
            setAddressStreet(event.target.value)
        }
        else if (event.target.id === "building")
        {
            setAddressBuilding(event.target.value)
        }
        else if (event.target.id === "flat")
        {
            setAddressFlat(event.target.value)
        }
    }


    function onAddressClose()
    {
        // check adres via api.

        setAddresses([])
        getUserLocation(addressCity, addressStreet, addressBuilding, setAddresses)
    }

    function onLogoutClick()
    {
        logout(setShowLoader, history)
    }

    function callbackAfterCancel()
    {
        history.goBack()
    }

    function goToLoyaltyCard()
    {
        history.push('/loyalty-zio')
    }

    function onAddressSelect(address_selected)
    {
        // set address on user. 
        setAddressCity(address_selected.city)
        setAddressStreet(address_selected.street)
        setAddressBuilding(address_selected.building_number)
        localStorage.setItem("primary_address_city", address_selected.city)
        localStorage.setItem("primary_address_street", address_selected.street)
        localStorage.setItem("primary_address_building_number", address_selected.building_number)
        localStorage.setItem("primary_address_flat_number", addressFlat)
        localStorage.setItem("primary_address_postcode", address_selected.postcode)
        localStorage.setItem("primary_address_latitude", address_selected.latitude)
        localStorage.setItem("primary_address_longitude", address_selected.longitude)
        localStorage.setItem("primary_address_postcode", address_selected.postcode)
        setUserPrimaryDeliveryAddress()
        setAddress(addressCity + ", " + addressStreet + " " + addressBuilding + " / " + addressFlat)
    }

    useEffect(() => {
        document.title = "moje konto"  
        if (isLoggedIn())
        {
            // setUserData()
            // getPromoCodes(setPromocodes)
            // if (!isCustomStore)
            // {
            //     getZioLoyaltyCardPointsFunction(setZioLoyaltyCardPoints)
            // }

            // if (!isUserAddressAdded() && getCurrentStoreDataKey("delivery_type").delivery)
            // {
            //     setOpenAddressDialog(true)
            // }
        }
    }, [])

    return (
    <>
        <Loader show={showLoader}/>
        {!isCustomStore?<Navbar showBack={true} showAddressBar={true} showUserOptions={true} />:<CustomNavbar tabIndex={2} storeData={ {logo_image_url:localStorage.getItem("custom_store_logo_image_url")} }/>}
        <NavbarSpace/>
        <LoginDialog callbackAfterLogin={callbackAfterLogin} callbackIfLoggedin={setUserData} callbackAfterCancel={callbackAfterCancel} />
        {addresses.length>0?<AddressSelectDialog title={"Wybierz adres"} items={addresses} onSelect={onAddressSelect} />:null}
        <YesNoDialog open={openDialog} setOpen={setOpenDialog} onYes={onAgreementYes} title={"UWAGA"} description={"Chesz odznaczyć zgode wymaganą. Odznaczenie zgody wymaganej spowoduje wylogowanie oraz wyczyszczenie danych konta. Odznaczyć i wylogować?"} />
        {login!==""?<Container>
            <Title>Moje konto</Title>
            <List style={{maxWidth:"480px"}}>                
                {temporaryUser===false?<CheckoutInfoRow title={"Mój login: " + login} icon={"user"} />:null}
                {showAddress?<CheckoutAddressRow title={"Adres: " + address } popupTitle={"Adres dostawy"} icon={"location"} onChange={updateDeliveryAddress} onClose={onAddressClose} openDialog={openAddressDialog} setOpenDialog={setOpenAddressDialog} />:null}
                {leganInformation?<CheckoutLegalInformationRow title={"Dane firmowe: " + leganInformation } icon={"legal"} />:null}
                
                {!isCustomStore?<CheckoutInfoRow title={"Program lojalnościowy: " + zioLoyaltyCardPoints + " pkt."} icon={"trophy"} buttonIcon={"arrow-right"} onClick={goToLoyaltyCard} />:null}
                {showPromoCodes?<> 
                    <CheckoutButtonRow title={"Dodaj kod promocyjny"} value={newPromoCode} popupTitle={"Kod"} icon={"promocode"} buttonIcon={"plus"} onChange={updatePromocode} onClose={onAddPromocodeClose} warrningText={promocodeWarrningMessage} successText={promocodeSuccessMessage} />
                    {promocodes.map( (promocode) => ( <PromocodeRow 
                        key={promocode.get("parent").id}
                        name={promocode.get("parent").get("name")} 
                        code={promocode.get("parent").get("code") } 
                        description={promocode.get("parent").get("description")} 
                        expirationDate={promocode.get("expirationDate")} 
                        minimumOrderValue={promocode.get("parent").get("minimumOrderValue")} 
                        value={promocode.get("parent").get("value")}                     
                        discountType={promocode.get("parent").get("discountType")}
                        deliveryType={promocode.get("parent").get("deliveryType")}         
                        storeObjectId={promocode.get("target")}    
                        history={history}      
                    /> ))}
                </>:null}
                <div>
                    <a style={{color:"black"}} href="https://www.web.ziofood.pl/media/a5nhoaou/regulamin-us%C5%82ugi-za-po%C5%9Brednictwem-aplikacji-zioapp.pdf" onClick={()=>{}}>*akceptuję regulamin</a>
                    <Switch
                        checked={agreement}
                        onChange={onAgreementClicked}
                        name="checkedA"
                        color="primary"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                      />
                </div>

            </List>
            <Button color="primary" onClick={onLogoutClick}>
               wyloguj
            </Button>
        </Container>:null}
    </>
  )
}

